<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useEditBillingAccount from "../../composables/edit-billing-account";

import ContactPersonField from "../form-fields/contact-person-field.vue";

import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
const { detailPageState } = useDetailPage();
const { editBillingAccountState, updateDeliveryMethodEmailAddress } = useEditBillingAccount();

const currentEmail = computed(() => {
  return detailPageState.value.data.billingAccountDetails.invoiceDeliveryEmail;
});

const options = computed(() => {
  return [
    {
      label: t("mybusiness.registeredRecipients"),
      value: "",
      selected: isCurrentEmailNotInAvailable(),
      disabled: true,
    },
    ...editBillingAccountState.value.data.availableEmails.map((availableEmail) => ({
      label: availableEmail.email,
      value: availableEmail.email,
      selected: currentEmail.value === availableEmail.email,
    })),
  ];
});

const submittingForm = computed(() => {
  return editBillingAccountState.value.layout.updateDeliveryMethod.loading;
});

const mobileNumber = computed(() => {
  return detailPageState.value.data.billingAccountDetails.phoneNumber;
});

const submit = ({ target }) => {
  const emailAddress = target[1].value;
  const phoneNumber = target.phoneNumber.value;

  updateDeliveryMethodEmailAddress(emailAddress, phoneNumber, false);
};

const isCurrentEmailNotInAvailable = () => {
  return !editBillingAccountState.value.data.availableEmails.some(
    (availableEmail) => availableEmail.email === currentEmail.value
  ); //.length > 0
};
</script>

<template>
  <telia-tab-content :name="t('mybusiness.changeRecipient')" t-id="edit-invoice-email-tab1">
    <telia-form name="updateRecipientForm" @submit.prevent="submit">
      <fieldset class="telia-fieldset">
        <legend v-if="currentEmail">
          <telia-p variant="paragraph-100"
            ><strong>{{ t("mybusiness.legend.newRecipient") }}</strong>
            <br />
            {{ currentEmail }}
          </telia-p>
        </legend>
        <telia-select
          t-id="edit-invoice-email-selection"
          :label="t('mybusiness.label.newRecipient')"
          :options="JSON.stringify(options)"
          name="email"
          required="true"
          :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
        />
        <contact-person-field :pre-populate-mobile-number="mobileNumber" />
        <div class="form__submit--wrapper">
          <b2x-loading-button variant="primary" type="submit" :is-loading="submittingForm"
            >{{ t("mybusiness.save_delivery_method") }}
          </b2x-loading-button>
        </div>
      </fieldset>
    </telia-form>
  </telia-tab-content>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

div.form__submit--wrapper {
  display: flex;
  align-items: center;
  margin-top: $telia-spacing-48;

  b2x-spinner {
    margin-left: $telia-spacing-12;
  }
}

fieldset.telia-fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
</style>
