<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../composables/detail-page";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { detailPageState } = useDetailPage();

const notificationHeader = computed(() => {
  return notFound.value
    ? t("fetchBillingAccount.sourceOrFetchAccountError.notFound.header")
    : t("fetchBillingAccount.sourceOrFetchAccountError.normal.header");
});

const notificationText = computed(() => {
  return notFound.value
    ? t("fetchBillingAccount.sourceOrFetchAccountError.notFound.content")
    : t("fetchBillingAccount.sourceOrFetchAccountError.normal.content");
});

const notificationLinkText = computed(() => {
  return t("fetchBillingAccount.sourceOrFetchAccountError.linkText");
});

const notificationLinkHref = computed(() => {
  return getHrefToBillingAccountsPageHref();
});

const notFound = computed(() => {
  return detailPageState.value.layout.fetchBillingAccount.notFound;
});

const getHrefToBillingAccountsPageHref = () => {
  const scopeId = detailPageState.value.data.loggedInUser.scopeId;
  return `/foretag/mybusiness/${scopeId}/fakturor/hantera-fakturering`;
};
</script>

<template>
  <b2x-result-message
    graphic="error"
    heading-tag="h1"
    :heading="notificationHeader"
    :complete-description="notificationText"
    action-type="link"
    :action-label="notificationLinkText"
    :link-href="notificationLinkHref"
    action-left-icon="arrow-left"
  ></b2x-result-message>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.notification {
  margin-top: $telia-spacing-32;
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 1.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}
</style>
