import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpAddressLookup } from "@telia/b2b-rest-client";
import type { LegalAddressResponseDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import type { GetAddressFromPointIdResponseDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";

export const getLegalAddress = async (tscid: string): Promise<LegalAddressResponseDTO> => {
  const scopeId: string = await getScopeIdOrThrow();
  return corpAddressLookup.AddressLookupControllerService.getLegalAddress(scopeId, tscid, true);
};

export const getAddressFromPointId = async (
  pointId: string
): Promise<GetAddressFromPointIdResponseDTO> => {
  return corpAddressLookup.OpenAddressLookupControllerService.getAddressFromPointId1(pointId);
};
