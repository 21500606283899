<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useEditBillingAccount from "../../composables/edit-billing-account";

import { translateMixin } from "../../locale";

import { SOURCE } from "../../services/interfaces/constants";

const t = translateMixin.methods.t;
const { detailPageState } = useDetailPage();
const { editBillingAccountState, updateDeliveryMethodPostal } = useEditBillingAccount();

const billingAccountDetails = computed(() => {
  return detailPageState.value.data.billingAccountDetails;
});

const submittingForm = computed(() => {
  return editBillingAccountState.value.layout.updateDeliveryMethod.loading;
});

const missingAddress = computed(() => {
  return (
    street.value === "" ||
    billingAccountDetails.value.zip === "" ||
    billingAccountDetails.value.city === ""
  );
});

const disableButton = computed(() => {
  return submittingForm.value || missingAddress.value;
});

const accountReference = computed(() => {
  return billingAccountDetails.value.accountReference;
});

const accountCO = computed(() => {
  return billingAccountDetails.value.accountCO;
});

const street = computed(() => {
  return billingAccountDetails.value.street;
});

const largerArea = computed(() => {
  return `${billingAccountDetails.value.zip}, ${billingAccountDetails.value.city}`;
});

const showReference = computed(() => {
  return (
    detailPageState.value.data.source.toLocaleLowerCase() !== SOURCE.BRM &&
    billingAccountDetails.value.accountReference !== ""
  );
});

const showCO = computed(() => {
  return billingAccountDetails.value.accountCO !== "";
});

const submitPostal = () => {
  const updateDeliveryMethodRequest = {
    city: billingAccountDetails.value.city,
    postalCode: billingAccountDetails.value.zip.trim().replace(" ", ""),
    reference: billingAccountDetails.value.accountReference,
    co: billingAccountDetails.value.accountCO,
    streetAddress: billingAccountDetails.value.street,
    deliveryMethod: "postal",
  };
  updateDeliveryMethodPostal(updateDeliveryMethodRequest);
};
</script>

<template>
  <div>
    <telia-notification status="information" heading-tag="h2">
      <span
        slot="heading"
        t-id="delivery-method-postal-info-heading"
        :button-text="t('mybusiness.close')"
      >
        {{ t("drawer.editDeliveryMethodAccount.postal.notification.heading") }}
      </span>
      <telia-text-spacing slot="content" t-id="delivery-method-postal-info-description">
        <telia-p>
          {{ t("drawer.editDeliveryMethodAccount.postal.notification.description") }}
        </telia-p>
      </telia-text-spacing>
    </telia-notification>
    <telia-form @submit.prevent="submitPostal" name="delivery-method-postal-form">
      <telia-heading
        class="postal--header"
        tag="h3"
        variant="title-200"
        t-id="delivery-method-postal-header"
      >
        {{ t("mybusiness.current_billing_address") }}
      </telia-heading>
      <telia-p
        class="postal--paragraph"
        variant="paragraph-200"
        t-id="delivery-method-postal-billing-address"
        v-if="showReference"
      >
        {{ accountReference }}</telia-p
      >
      <telia-p
        class="postal--paragraph"
        variant="paragraph-200"
        t-id="delivery-method-postal-billing-address"
        v-if="showCO"
      >
        {{ accountCO }}</telia-p
      >
      <telia-p
        class="postal--paragraph"
        variant="paragraph-200"
        t-id="delivery-method-postal-billing-address"
      >
        {{ street }}</telia-p
      >
      <telia-p
        class="postal--paragraph"
        variant="paragraph-200"
        t-id="delivery-method-postal-billing-address"
      >
        {{ largerArea }}</telia-p
      >
      <div class="form__submit--wrapper">
        <b2x-loading-button
          t-id="delivery-method-postal-button"
          variant="primary"
          type="submit"
          :is-loading="submittingForm"
          >{{ t("mybusiness.save_delivery_method") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

div.form__submit--wrapper {
  display: flex;
  align-items: center;
  margin-top: $telia-spacing-48;

  b2x-spinner {
    margin-left: $telia-spacing-12;
  }
}

.postal--header {
  margin-top: $telia-spacing-48;
  margin-bottom: $telia-spacing-2;
}

.postal--paragraph {
  margin-top: $telia-spacing-2;
  margin-bottom: $telia-spacing-2;
}

telia-notification {
  padding: $telia-spacing-24;
}
</style>
