<template>
  <div class="create-billing-account" t-id="create-brm-billing-account-content">
    <telia-form
      t-id="billing-account-form"
      class="billing-account-form"
      @submit.prevent="submit"
      novalidate
    >
      <div class="billing-account-form__field-container">
        <telia-label html-for="referenceInputId">
          {{ t("BILLING_ACCOUNT_FORM_NAME_LABEL") }}
        </telia-label>
        <div class="billing-account-form__reference-input-container">
          <telia-text-input
            t-id="billing-account-form__reference"
            class="billing-account-form__reference-input"
            input-id="referenceInputId"
            name="reference"
            type="text"
            required
            :required-error-message="t('BILLING_ACCOUNT_FORM_FIELD_IS_REQUIRED')"
            :value="reference"
            @input="reference = $event.target.value"
          />
          <div class="billing-account-form__reference-tooltip-container">
            <b2x-tooltip
              t-id="billing-account-form__reference-tooltip"
              class="billing-account-form__reference-tooltip"
              :content="t('BILLING_ACCOUNT_FORM_REFERENCE_TOOLTIP')"
              placement="left"
            />
          </div>
        </div>
      </div>
      <div class="billing-account-form__field-container">
        <address-input
          v-if="!defaultAddressIsPending"
          t-id="billing-account-form__address"
          :default-address="defaultAddress"
          @address-selected="setSelectedAddress"
          @input-mode-changed="handleAddressInputModeChanged"
        />
      </div>
      <div class="billing-account-form__field-container">
        <delivery-method
          :selected-delivery-method="selectedDeliveryMethod"
          @change-delivery-method="selectedDeliveryMethod = $event"
        />
        <telia-text-input
          v-show="selectedDeliveryMethod === DeliveryMethods.EMAIL"
          class="billing-account-form__email-input"
          t-id="billing-account-form__email-input"
          type="email"
          autocomplete="email"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
          :pattern-error-message="t('BILLING_ACCOUNT_FORM_EMAIL_LABEL_INVALID')"
          required
          :required-error-message="t('BILLING_ACCOUNT_FORM_FIELD_IS_REQUIRED')"
          :label="t('BILLING_ACCOUNT_FORM_EMAIL_LABEL')"
          :value="emailAddress"
          @input="emailAddress = $event.target.value"
        />
        <telia-p v-if="selectedDeliveryMethod === DeliveryMethods.EMAIL">
          {{ t("BILLING_ACCOUNT_FORM_EMAIL_POLICY") }}
          <telia-link
            t-id="billing-account-form__email-policy-link"
            href="/foretag/om/integritetspolicy"
            target="_blank"
            >{{ t("BILLING_ACCOUNT_FORM_EMAIL_POLICY_LINK") }}
          </telia-link>
        </telia-p>
        <telia-p t-id="create-billing-account__letter-invoice-fee" v-if="showLetterInvoiceFeeText">
          {{ t("BILLING_ACCOUNT_FORM_LETTER_INVOICE_FEE") }}
        </telia-p>
      </div>
      <div class="billing-account-form__field-container">
        <billing-cycle
          t-id="create-billing-account__billing-cycle"
          :billing-cycles="billingCycles"
          :selected-billing-cycle="selectedBillingCycle"
          @change-billing-cycle="selectedBillingCycle = $event"
        ></billing-cycle>

        <telia-p t-id="create-billing-account__billing-terms" v-if="paymentDueDateDays">
          {{ billingTermsText }}
        </telia-p>
      </div>

      <div class="billing-account-form__action-container">
        <telia-notification
          v-if="moveSubscriptionsState.layout.moveSubscriptions.newAccount.error"
          heading-tag="h3"
          variant="inline"
        >
          <span slot="heading">{{ t("CREATE_BILLING_ACCOUNT_ERROR_TITLE") }}</span>
          <telia-text-spacing slot="content">
            <telia-p> {{ t("CREATE_BILLING_ACCOUNT_MESSAGE") }} </telia-p>
          </telia-text-spacing>
        </telia-notification>
        <div class="billing-account-form__action-container-buttons">
          <telia-button
            t-id="billing-account-form__submit-button"
            class="billing-account-form__button"
            variant="primary"
            type="submit"
            :disabled="createButtonDisabled"
          >
            {{ t("BILLING_ACCOUNT_FORM_CREATE_ACCOUNT") }}
          </telia-button>
        </div>
      </div>
    </telia-form>
  </div>
</template>

<script setup lang="ts">
import type { AddressRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";
import { computed, ComputedRef, ref, Ref } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import type { DeliveryMethodValues, InputModeValues } from "../../typings/types";
import { toAddressRequestDTO } from "../../utils/address-mapper";
import { DeliveryMethods, InputMode } from "../../utils/constants";
import { formIsValid } from "../../utils/validation";
import AddressInput from "./address-input.vue";
import BillingCycle from "./billing-cycle.vue";
import DeliveryMethod from "./delivery-method.vue";
import { useAgreementBillingTerms } from "../../composables/useAgreementBillingTerms";
import { useDefaultAddress } from "../../composables/use-default-address";
import useMoveSubscriptions from "../../composables/move-subscriptions";

const componentRef = ref(0);
loadTranslations();
async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

const { moveSubscriptionsState, moveToNewBrmAccount } = useMoveSubscriptions();

const { paymentDueDateDays, hasLetterInvoiceFee, billingCycles, selectedBillingCycle } =
  useAgreementBillingTerms(
    moveSubscriptionsState.value.data.scopeId,
    moveSubscriptionsState.value.data.tscid
  );

const { defaultAddress, defaultAddressIsPending } = useDefaultAddress(
  moveSubscriptionsState.value.data.tscid
);

const t = translateMixin.methods.t;

const reference: Ref<string> = ref("");

const emailAddress: Ref<string> = ref("");
const selectedDeliveryMethod: Ref<DeliveryMethodValues> = ref(DeliveryMethods.EMAIL);
const currentInputMode: Ref<InputModeValues> = ref(InputMode.SEARCH);
const address: Ref<AddressRequestDTO | null> = ref(
  toAddressRequestDTO(defaultAddress.value) ?? null
);
const createButtonDisabled: ComputedRef<boolean> = computed(() => {
  return (
    !formIsValid(
      selectedDeliveryMethod.value,
      reference.value,
      address.value,
      emailAddress.value
    ) || moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.loading
  );
});

const showLetterInvoiceFeeText: ComputedRef<boolean> = computed(() => {
  return selectedDeliveryMethod.value === DeliveryMethods.LETTER && hasLetterInvoiceFee.value;
});

const billingTermsText: ComputedRef<string> = computed(() => {
  return t("BILLING_ACCOUNT_FORM_BILLING_PERIODICITY", {
    paymentDueDateDays: paymentDueDateDays.value as number,
  });
});

function setSelectedAddress(selectedAddress: AddressRequestDTO): void {
  address.value = selectedAddress;
}

function handleAddressInputModeChanged(mode: InputModeValues): void {
  currentInputMode.value = mode;
}

function submit() {
  const scopeId = moveSubscriptionsState.value.data.scopeId;
  const tscid = moveSubscriptionsState.value.data.tscid;
  const currentBillingAccountNumber = moveSubscriptionsState.value.data.accountNumber;
  const subscriptionIds = moveSubscriptionsState.value.data.selectedSubscriptions.map(
    (sub) => sub.subscriptionId
  );

  moveToNewBrmAccount(
    scopeId,
    tscid,
    subscriptionIds,
    reference.value,
    address.value!,
    selectedDeliveryMethod.value,
    currentBillingAccountNumber,
    emailAddress.value,
    selectedBillingCycle.value
  );
}
</script>

<style lang="scss">
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.billing-account-form {
  display: block;
  padding-top: $telia-spacing-8;

  &__email-input {
    margin-bottom: $telia-spacing-16;
  }

  > form {
    padding-bottom: 0;
  }

  &__action-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: $telia-spacing-32;

    &-buttons {
      display: flex;
    }
  }

  &__field-container + &__field-container {
    margin-top: $telia-spacing-32;
  }

  &__reference-input-container {
    position: relative;
    padding-right: $telia-spacing-48;

    .telia-label {
      display: none;
    }
  }

  &__reference-tooltip-container {
    position: absolute;
    top: $telia-spacing-16;
    right: 0;
  }

  &__reference-tooltip {
    padding: $telia-spacing-16;
  }

  &__button + &__button {
    margin-left: $telia-spacing-12;
  }
}
</style>
