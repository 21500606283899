<script setup lang="ts">
import { ref, watch } from "vue";

import { translateMixin } from "../../locale";

const props = withDefaults(
  defineProps<{
    accountReferenceValue?: string;
    accountCOValue?: string;
    streetValue?: string;
    zipValue?: string;
    cityValue?: string;
    showAccountReferenceField?: boolean;
    accountReferenceFieldLabel?: string;
    showAccountCOField?: boolean;
    accountCOFieldLabel?: string;
  }>(),
  {
    accountReferenceValue: "",
    accountCOValue: "",
    streetValue: "",
    zipValue: "",
    cityValue: "",
    showAccountReferenceField: true,
    accountReferenceFieldLabel: "",
    showAccountCOField: true,
    accountCOFieldLabel: "",
  }
);

const t = translateMixin.methods.t;
const addressMaxLength = ref({
  city: 27,
  postalCode: 5,
  reference: 40,
  co: 40,
  streetAddress: 35,
});

const formatZipCode = (zipCode: string) => {
  return zipCode.trim().replace(/(\d{3})\W?(\d{1,2})/, "$1 $2");
};

const postNumberInputValue = ref(formatZipCode(props.zipValue));
const zipCodeRegexPattern = "^\\d{3}\\s*\\d{2}$"; //starts with 3 numbers, space in between, ends with 2 numbers

const handleInputValueChange = ({ target }) => {
  let cursorLocation = target.selectionStart;
  const valueLength = target.value.length;
  target.value = formatZipCode(target.value);
  if (target.value.length === valueLength + 1) cursorLocation++;
  target.setSelectionRange(cursorLocation, cursorLocation);
};

watch(
  () => props.zipValue,
  (newVal: string) => {
    postNumberInputValue.value = formatZipCode(newVal);
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <telia-text-input
      v-if="showAccountReferenceField"
      :label="accountReferenceFieldLabel"
      type="text"
      :additional="t('drawer.shared.form.maxLength', { 0: addressMaxLength.reference })"
      name="accountReference"
      :maxlength="addressMaxLength.reference"
      :value="accountReferenceValue"
    ></telia-text-input>
    <telia-text-input
      v-if="showAccountCOField"
      :label="accountCOFieldLabel"
      type="text"
      :additional="t('drawer.shared.form.maxLength', { 0: addressMaxLength.co })"
      name="accountCO"
      :maxlength="addressMaxLength.co"
      :value="accountCOValue"
    ></telia-text-input>
    <telia-text-input
      :label="t('mybusiness.street')"
      :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
      type="text"
      :additional="t('drawer.shared.form.maxLength', { 0: addressMaxLength.streetAddress })"
      :maxlength="addressMaxLength.streetAddress"
      name="street"
      :value="streetValue"
    ></telia-text-input>
    <telia-text-input
      :label="t('mybusiness.form.label.zip')"
      required="true"
      :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
      type="string"
      :additional="t('drawer.shared.form.requiredMessage', { 0: '5' })"
      maxlength="6"
      name="zip"
      :pattern="zipCodeRegexPattern"
      :pattern-error-message="t('drawer.shared.form.zipCodePatternErrorMessage')"
      :value="postNumberInputValue"
      @input="handleInputValueChange"
    ></telia-text-input>
    <telia-text-input
      :label="t('mybusiness.city')"
      required="true"
      :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
      type="text"
      :additional="t('drawer.shared.form.requiredMessage', { 0: addressMaxLength.city })"
      :maxlength="addressMaxLength.city"
      name="city"
      :value="cityValue"
    ></telia-text-input>
  </div>
</template>
