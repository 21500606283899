<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";

import useDetailPage from "./composables/detail-page";

import { translateSetup } from "./locale";

import DetailCard from "./components/detail-card.vue";
import DetailTable from "./components/detail-table.vue";
import DetailSearch from "./components/detail-search.vue";
import DetailInfoModal from "./components/detail-info-modal.vue";
import DetailDescription from "./components/detail-description.vue";
import DetailMoveSubscription from "./components/detail-move-subscription.vue";
import BillingAccountNotFound from "./components/billing-account-not-found.vue";
import MoveSubscriptionDrawer from "./components/detail-move-subscription-drawer/move-subscription-drawer.vue";
import EditBillingAccountDrawer from "./components/detail-edit-billing-account-drawer/edit-billing-account-drawer.vue";
import { useFlag } from "@unleash/proxy-client-vue";

const reRenderKey = ref(0);

const { detailPageState, initialize, paginationChange, sourceIsBRM } = useDetailPage();

const isBrmEnabled = useFlag("b2b-move-brm-billing-accounts");

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  initialize();
  reRenderKey.value++;
});

const emptyList = computed(() => {
  return !layoutLoading.value && detailPageState.value.data.tableData.length === 0;
});

const layoutLoading = computed(() => {
  return detailPageState.value.layout.fetchBillingAccount.loading;
});

const pagination = computed(() => {
  return detailPageState.value.data.pagination;
});

const loadDetailSearch = computed(() => {
  return layoutLoading.value ? "b2x-skeleton-input" : DetailSearch;
});

const isMobileBillingAccount = computed(() => {
  return detailPageState.value.data.billingAccountDetails.isMobileBillingAccount;
});

const isBRM = computed(() => {
  return isBrmEnabled.value && sourceIsBRM();
});

const billingAccountNotFoundOrSourceError = computed(() => {
  return (
    detailPageState.value.layout.fetchBillingAccount.error ||
    detailPageState.value.layout.fetchBillingAccount.notFound ||
    detailPageState.value.layout.fetchBillingAccount.sourceError
  );
});

const handlePaginationChange = (event: CustomEvent) => {
  paginationChange(event.detail);
};
</script>

<template>
  <section class="detail-page" t-id="b2b-billing-account-detail-page" :key="reRenderKey">
    <telia-grid t-id="table-grid-wrapper">
      <billing-account-not-found v-if="billingAccountNotFoundOrSourceError" />

      <div v-else class="grid">
        <detail-description class="detail-description" />
        <detail-card class="detail-card" />
        <component :is="loadDetailSearch" class="detail-search"></component>
        <detail-move-subscription
          v-if="isMobileBillingAccount || isBRM"
          class="detail-move-subscription"
        />
        <detail-table class="detail-table"></detail-table>
        <move-subscription-drawer v-if="isMobileBillingAccount || isBRM" />
        <edit-billing-account-drawer
          v-if="!layoutLoading"
          :scope-id="detailPageState.data.loggedInUser.scopeId"
          :organisation-tscid="detailPageState.data.organisationTscid"
          :account-number="detailPageState.data.billingAccountDetails.accountNumber"
          :source="detailPageState.data.source"
        />
        <b2x-paginator
          v-if="!emptyList"
          class="b2x-paginator"
          :list-length="pagination.total"
          :page-sizes="'[10, 25, 50, 100]'"
          :default-page-size="pagination.pageSize"
          t-id="b2x-paginator"
          @paginationChange="handlePaginationChange"
        ></b2x-paginator>
      </div>
    </telia-grid>
    <detail-info-modal />
  </section>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/breakpoints/variables";
@import "@teliads/components/foundations/spacing/variables";

.detail-description,
.detail-card {
  padding-top: $telia-spacing-32;
}

.detail-description {
  grid-area: detail-description;
}

.detail-card {
  grid-area: detail-card;
}

.table-header {
  grid-area: table-header;
}

.detail-search {
  grid-area: detail-search;
  padding: $telia-spacing-16 0;
  max-width: 75%;
}

.detail-move-subscription {
  grid-area: detail-move-subscription;
  margin-bottom: $telia-spacing-16;
}

.detail-table {
  grid-area: detail-table;
  min-height: 30rem;
}

.b2x-paginator {
  grid-area: b2x-paginator;
}

.grid {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    "detail-description"
    "detail-card"
    "table-header"
    "detail-search"
    "detail-move-subscription"
    "detail-table"
    "b2x-paginator";
}

@media screen and (min-width: $telia-fluid-breakpoint-medium) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "detail-description detail-description detail-card detail-card"
      "detail-search detail-move-subscription detail-move-subscription ....."
      "detail-table detail-table detail-table detail-table"
      "b2x-paginator b2x-paginator b2x-paginator b2x-paginator";
  }

  .detail-move-subscription {
    display: flex;
    align-items: flex-end;
  }

  .detail-move-subscription {
    margin-left: $telia-spacing-32;
  }

  .detail-search {
    max-width: 100%;
  }
}
</style>
./composables/detail-page
