import { corpCustomerBillingAccounts, corpBillingAccounts } from "@telia/b2b-rest-client";
import { IAvailableEmails } from "./interfaces/IEditBillingAccountState";
import { IBillingAccount } from "./interfaces/IMoveSubscriptionState";
import { ISubscription, IBillingAccountDetails } from "./interfaces/IState";
import type {
  MoveSubscriptionsBRMResponseDTO,
  MoveSubscriptionToNewBrmBillingAccountRequestDTO,
} from "@telia/b2b-rest-client/dist/corp-customer-billing-accounts";

export const fetchBillingAccount = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string,
  source: string
): Promise<IBillingAccountDetails> => {
  return new Promise<IBillingAccountDetails>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getBillingAccount(
      scopeId,
      tscid,
      billingAccountNumber,
      source
    )
      .then((data: corpCustomerBillingAccounts.GetBillingAccountDTO) => {
        resolve(_mapGetBillingAccountDTOtoIBillingAccountDetails(data));
      })
      .catch((error) => reject(error));
  });
};

export const fetchSubscriptionsList = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string,
  source: string
): Promise<ISubscription[]> => {
  return new Promise<ISubscription[]>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getSubscriptions(
      scopeId,
      tscid,
      billingAccountNumber,
      source
    )
      .then((data: corpCustomerBillingAccounts.SubscriptionDTO[]) => {
        const subscriptions: ISubscription[] = data.map(
          (subscriptionDTO: corpCustomerBillingAccounts.SubscriptionDTO) => ({
            offeringName: subscriptionDTO.offeringName || "",
            subscriptionId: subscriptionDTO.subscriptionId || "",
            user: subscriptionDTO.user || "",
          })
        );
        resolve(subscriptions);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchBillingAccounts = (
  scopeId: string,
  tscid: string,
  billingAccountNr: string
): Promise<IBillingAccount[] | []> => {
  return new Promise((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getMobileBillingAccountsForTscid(
      scopeId,
      tscid,
      billingAccountNr
    )
      .then(
        //@ts-expect-error  // Getting error 'TS2345'
        (
          data: corpCustomerBillingAccounts.GetMobileBillingAccountsForTscidDTO
        ): void | PromiseLike<void> => {
          const mappedData: IBillingAccount[] =
            _mapGetAllMobileBillingAccountsForTscidDTOToIBillingAccount(data);
          resolve(mappedData);
        }
      )

      .catch((error) => reject(error));
  });
};

export const fetchBrmBillingAccounts = (
  scopeId: string,
  tscid: string
): Promise<IBillingAccount[] | []> => {
  return new Promise((resolve, reject) => {
    corpBillingAccounts.AccountManagementControllerService.getBillingAccountsForTscid1(
      scopeId,
      tscid
    )
      .then((data: corpBillingAccounts.BillingAccountDTO[]): void | PromiseLike<void> => {
        const mappedData: IBillingAccount[] =
          _mapGetAllBRMBillingAccountsForTscidDTOToIBillingAccount(data);
        resolve(mappedData);
      })

      .catch((error) => reject(error));
  });
};

export const fetchAvailableEmails = (
  scopeId: string,
  tscid: string
): Promise<IAvailableEmails[]> => {
  return new Promise<IAvailableEmails[]>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getAvailableEmails(scopeId, tscid)
      .then((response) => resolve(response as IAvailableEmails[]))
      .catch((error) => reject(error));
  });
};

export const moveSubscriptionsToExistingMobillAccount = (
  scopeId: string,
  tscid: string,
  accountId: string,
  subscriptionsList: corpCustomerBillingAccounts.MoveSubscriptionsRequestDTO
): Promise<corpCustomerBillingAccounts.MoveSubscriptionDTO> => {
  return new Promise<corpCustomerBillingAccounts.MoveSubscriptionDTO>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.moveSubscriptionsToExistingMobillBillingAccount(
      scopeId,
      tscid,
      accountId,
      subscriptionsList
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const moveSubscriptionsToExistingBrmAccount = (
  scopeId: string,
  tscid: string,
  requestBody: corpCustomerBillingAccounts.MoveSubscriptionsBRMRequestDTO
): Promise<corpCustomerBillingAccounts.MoveSubscriptionsBRMResponseDTO> =>
  corpCustomerBillingAccounts.BillingAccountsControllerService.moveSubscriptionsToExistingBrmBillingAccount(
    scopeId,
    tscid,
    requestBody
  );

export const createNewCorpBillingAccount = (
  scopeId: string,
  tscid: string,
  moveToNewBillingAccountRequest: corpCustomerBillingAccounts.MoveSubscriptionToNewBillingAccountRequestDTO
): Promise<corpCustomerBillingAccounts.MoveSubscriptionDTO> => {
  return new Promise<corpCustomerBillingAccounts.MoveSubscriptionDTO>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.moveSubscriptionToNewBillingAccount(
      scopeId,
      tscid,
      moveToNewBillingAccountRequest
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const moveSubscriptionsToNewBrmAccount = async (
  scopeId: string,
  tscid: string,
  requestBody: MoveSubscriptionToNewBrmBillingAccountRequestDTO
): Promise<MoveSubscriptionsBRMResponseDTO> =>
  corpCustomerBillingAccounts.BillingAccountsControllerService.moveSubscriptionToNewBrmBillingAccount(
    scopeId,
    tscid,
    requestBody
  );

export const updateBillingAddress = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string,
  source: string,
  updateBillingAddressRequest: corpCustomerBillingAccounts.UpdateBillingAddressRequestDTO
): Promise<corpCustomerBillingAccounts.UpdateBillingAccountDTO> => {
  return new Promise<corpCustomerBillingAccounts.UpdateBillingAccountDTO>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.updateBillingAddress(
      scopeId,
      tscid,
      billingAccountNumber,
      source,
      updateBillingAddressRequest
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateDeliveryMethod = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string,
  source: string,
  updateDeliveryMethodRequest: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.updateDeliveryMethod(
      scopeId,
      tscid,
      billingAccountNumber,
      source,
      updateDeliveryMethodRequest
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getBillingAccountSource = (
  scopeId: string,
  tscid: string,
  billingAccountNumber: string
): Promise<corpCustomerBillingAccounts.GetSourceDTO> => {
  return new Promise<corpCustomerBillingAccounts.GetSourceDTO>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getSource(
      scopeId,
      tscid,
      billingAccountNumber
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const _mapGetAllMobileBillingAccountsForTscidDTOToIBillingAccount = (
  data: corpCustomerBillingAccounts.GetMobileBillingAccountsForTscidDTO
): IBillingAccount[] => {
  return (data as corpCustomerBillingAccounts.GetMobileBillingAccountsForTscidDTO[]).map(
    (
      account: corpCustomerBillingAccounts.GetMobileBillingAccountsForTscidDTO
    ): IBillingAccount => ({
      accountNumber: account.accountNumber || "",
      displayName: _generateMobileDisplayName(account),
      number: account.number || "",
    })
  );
};

const _mapGetAllBRMBillingAccountsForTscidDTOToIBillingAccount = (
  data: corpBillingAccounts.BillingAccountDTO[]
): IBillingAccount[] => {
  return data.map(
    (account: corpBillingAccounts.BillingAccountDTO): IBillingAccount => ({
      accountNumber: account.id || "",
      displayName: _generateBRMDisplayName(account),
      number: "",
    })
  );
};

const _generateBRMDisplayName = (account: corpBillingAccounts.BillingAccountDTO): string => {
  let displayName = account.id || "";
  if (account.reference) {
    displayName = `${displayName} / ${account.reference}`;
  }
  return displayName;
};

const _generateMobileDisplayName = (
  account: corpCustomerBillingAccounts.GetMobileBillingAccountsForTscidDTO
): string => {
  let displayName = account.accountNumber || "";
  if (account.accountReference) {
    displayName = `${displayName} / ${account.accountReference}`;
  }
  return displayName;
};

const _mapGetBillingAccountDTOtoIBillingAccountDetails = (
  account: corpCustomerBillingAccounts.GetBillingAccountDTO
): IBillingAccountDetails => {
  return {
    number: account.number || "",
    accountNumber: account.accountNumber || "",
    billingAddress: _getBillingAddress(account),
    phoneNumber: account.phoneNumber || "",
    accountReference: account.accountReference || "",
    accountCO: account.accountCO || "",
    invoiceDeliveryEmail: account.billingAccountDelivery?.invoiceDeliveryEmail || "",
    invoiceDeliveryType: account.billingAccountDelivery?.invoiceDeliveryMethod || "",
    invoiceDeliveryMethodKey: _getDeliveryMethodKey(
      account.billingAccountDelivery?.invoiceDeliveryMethod || ""
    ),
    billingName: account.organisation?.billingName || "",
    organisationNumber: account.organisation?.organisationNumber || "",
    isMobileBillingAccount: account.isMobileBillingAccount || false,
    city: account.billingAddress?.city || "",
    zip: _parseZipCode(account.billingAddress?.zipCode || ""),
    street: account.billingAddress?.address || "",
  };
};

const _getDeliveryMethodKey = (deliveryMethod: string): string => {
  const translationKeys = {
    EMAIL: "mybusiness.email",
    POSTAL: "mybusiness.letter",
    EDI: "mybusiness.edi",
    EDI_AND_LETTER: "mybusiness.ediAndLetter",
    EINVOICE: "mybusiness.einvoice",
  };
  return translationKeys[deliveryMethod] || "";
};

const _parseZipCode = (zipCode: string): string => {
  return zipCode.replace(/(.{3})/, "$1 ");
};

const _getBillingAddress = (account: corpCustomerBillingAccounts.GetBillingAccountDTO): string => {
  return `${
    account.billingAddress?.address ? account.billingAddress?.address?.concat(",") : ""
  } ${_parseZipCode(account.billingAddress?.zipCode || "")} ${account.billingAddress?.city || ""}`;
};
