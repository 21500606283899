import { IEditBillingAccountState } from "../interfaces/IEditBillingAccountState";
import { IMoveSubscriptionState } from "../interfaces/IMoveSubscriptionState";
import { IInfoModal, IState } from "../interfaces/IState";

export const detailPageServiceCreateState = (): IState => ({
  layout: {
    fetchBillingAccount: {
      error: false,
      loading: true,
      notFound: false,
      sourceError: false,
    },
    editBillingAccount: {
      serviceIsCreated: false,
    },
    fetchSubscriptionList: {
      error: false,
      loading: true,
    },
    moveSubscriptions: {
      serviceIsCreated: false,
    },
  },
  data: {
    billingAccountDetails: {
      number: "",
      accountNumber: "",
      phoneNumber: "",
      billingAddress: "",
      invoiceDeliveryMethodKey: "",
      invoiceDeliveryEmail: "",
      invoiceDeliveryType: "",
      accountReference: "",
      accountCO: "",
      billingName: "",
      organisationNumber: "",
      isMobileBillingAccount: false,
      city: "",
      zip: "",
      street: "",
    },
    accountSubscriptions: [],
    tableData: [],
    loggedInUser: { scopeId: "" },
    organisationTscid: "",
    filterValue: "",
    pagination: {
      pageSize: 25,
      page: 1,
      total: 1,
    },
    selectedSubscriptions: [],
    source: "",
  },
});

export const createStatemoveSubscription = (): IMoveSubscriptionState => ({
  data: {
    scopeId: "",
    tscid: "",
    accountNumber: "",
    source: "",
    billingAccounts: [],
    selectedSubscriptions: [],
  },
  layout: {
    fetchBillingAccounts: {
      loading: false,
      error: false,
    },
    moveSubscriptions: {
      existingAccount: {
        loading: false,
        error: false,
      },
      newAccount: {
        loading: false,
        error: false,
      },
      isDrawerOpen: false,
    },
  },
});

export const createEditBillingAccountState = (): IEditBillingAccountState => ({
  layout: {
    isDrawerOpen: false,
    updateBillingAddress: {
      loading: false,
    },
    updateDeliveryMethod: {
      loading: false,
    },
    notification: {
      show: false,
      message: "",
      header: "",
      status: "",
    },
  },
  data: {
    scopeId: "",
    tscid: "",
    source: "",
    accountNumber: "",
    availableEmails: [],
    updatedData: { deliveryMethod: "" },
  },
});

export const createInfoModalState = (): IInfoModal => ({
  show: false,
  header: "",
  firstContentRow: "",
  secondContentRow: "",
  thirdContentRow: "",
  isMoveSubscription: false,
});
