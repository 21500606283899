<script setup lang="ts">
import { Ref } from "vue";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-vue";
import { useUnleashClientWithScopeId } from "@telia/b2b-unleash-client-vue3/dist/b2b-unleash-client-vue3.esm";
import B2bBillingAccountDetailPage from "./b2b-billing-account-detail-page.vue";
const client: Ref<UnleashClient | null> = useUnleashClientWithScopeId(
  "b2b-billing-account-detail-page"
);
</script>

<template>
  <FlagProvider v-if="client !== null" :unleash-client="client" :start-client="false">
    <B2bBillingAccountDetailPage />
  </FlagProvider>
</template>

<style scoped lang="scss"></style>
