import type { GetAddressFromPointIdResponseDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import type { LegalAddressStructuredSuggestionDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";
import { DefaultAddress } from "../typings/types";
import { AddressRequestDTO } from "@telia/b2b-rest-client/dist/corp-billing-accounts";

export function toDefaultAddress(
  address: GetAddressFromPointIdResponseDTO | LegalAddressStructuredSuggestionDTO | undefined
): DefaultAddress {
  let adr = address;
  if (!adr) {
    adr = {};
  }
  return {
    pointId: adr.pointId,
    streetName: adr.streetName,
    streetNumber: adr.streetNumber,
    entrance: adr.entrance,
    postalCode: adr.postalCode,
    city: adr.city,
    fullAddress: adr.fullAddress,
  };
}

export function toAddressRequestDTO(address?: DefaultAddress): AddressRequestDTO | undefined {
  if (!address) return;
  return {
    streetName: address.streetName ?? "",
    postalCode: address.postalCode ?? "",
    city: address.city ?? "",
    streetNumber: address.streetNumber,
    entrance: address.entrance,
  };
}
