<script setup lang="ts">
import { computed, ref, watch } from "vue";

import useDetailPage from "../../composables/detail-page";

import { translateMixin } from "../../locale";

import EditInvoiceEdiItem from "./edit-invoice-edi-item.vue";
import EditInvoiceEmailItem from "./edit-invoice-email-item.vue";
import EditInvoicePostalItem from "./edit-invoice-postal-item.vue";

import { DELIVERY_METHODS } from "../../services/interfaces/constants";
import useEditBillingAccount from "../../composables/edit-billing-account";

const emit = defineEmits<{ (e: "contact-support"): void }>();
const t = translateMixin.methods.t;
const { detailPageState, deliveryTypeIsEdi, deliveryTypeIsEmail, deliveryTypeIsPostal } =
  useDetailPage();
const { editBillingAccountState } = useEditBillingAccount();

const currentDeliveryMethod = computed(() => {
  return detailPageState.value.data.billingAccountDetails.invoiceDeliveryType;
});

const loadTemplate = computed(() => {
  switch (currentDeliveryMethod.value) {
    case DELIVERY_METHODS.EMAIL:
      return EditInvoiceEmailItem;
    case DELIVERY_METHODS.POSTAL:
      return EditInvoicePostalItem;
    case DELIVERY_METHODS.EDI:
      return EditInvoiceEdiItem;
    default:
      return "";
  }
});

const changeDeliveryMethods = (deliveryMethod: string) => {
  detailPageState.value.data.billingAccountDetails.invoiceDeliveryType = deliveryMethod;
};

const isDrawerOpen = computed(() => {
  return editBillingAccountState.value.layout.isDrawerOpen;
});

const accordionItem = ref();

watch(
  () => isDrawerOpen.value,
  () => {
    setTimeout(() => {
      accordionItem.value.shadowRoot.querySelector("button").click();
    }, 25);
  }
);
</script>

<template>
  <telia-accordion-item
    ref="accordionItem"
    :heading="t('mybusiness.how_receive_invoice')"
    heading-tag="h2"
  >
    <div class="fieldset-wrapper">
      <telia-fieldset
        class="delivery-method-fieldset"
        t-id="delivery-method-fieldset"
        :legend="t('drawer.editDeliveryMethodAccount.legend')"
      >
        <telia-radio-button
          name="delivery-method"
          :label="t('mybusiness.by_email')"
          :disabled="deliveryTypeIsEdi()"
          :checked="deliveryTypeIsEmail()"
          @change="changeDeliveryMethods(DELIVERY_METHODS.EMAIL)"
          t-id="delivery-method-email"
        ></telia-radio-button>
        <telia-radio-button
          name="delivery-method"
          :label="t('mybusiness.by_post')"
          :disabled="deliveryTypeIsEdi()"
          :checked="deliveryTypeIsPostal()"
          @change="changeDeliveryMethods(DELIVERY_METHODS.POSTAL)"
          t-id="delivery-method-postal"
        ></telia-radio-button>
        <telia-radio-button
          v-if="deliveryTypeIsEdi()"
          name="delivery-method"
          :label="t('mybusiness.by_edi')"
          :checked="deliveryTypeIsEdi()"
          t-id="delivery-method-edi"
          @change="changeDeliveryMethods(DELIVERY_METHODS.EDI)"
        ></telia-radio-button>
      </telia-fieldset>
    </div>
    <component
      class="accordion-content"
      :is="loadTemplate"
      @contact-support="emit('contact-support')"
    ></component>
  </telia-accordion-item>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables";
@import "@teliads/components/foundations/spacing/variables";

telia-accordion-item {
  --vds-accordion-item-content-max-width: 100%;
}

div.fieldset-wrapper {
  padding: $telia-spacing-12 $telia-spacing-12 $telia-spacing-24 $telia-spacing-32;
  background-color: $telia-gray-50;
  margin-bottom: $telia-spacing-32;
  box-sizing: border-box;
}

.accordion-content {
  padding: 0 $telia-spacing-12 $telia-spacing-12 $telia-spacing-12;
}
</style>
