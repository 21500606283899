import { Ref, ref } from "vue";
import { getLegalAddress } from "../services/addressLookup";
import { toDefaultAddress } from "../utils/address-mapper";
import { DefaultAddress } from "../typings/types";

interface DefaultAddressComposable {
  defaultAddress: Ref<DefaultAddress | undefined>;
  defaultAddressIsPending: Ref<boolean>;
}

export function useDefaultAddress(tscid: string): DefaultAddressComposable {
  const defaultAddress: Ref<DefaultAddress | undefined> = ref();
  const defaultAddressIsPending: Ref<boolean> = ref(true);
  getLegalAddress(tscid)
    .then((response) => {
      defaultAddress.value = toDefaultAddress(response?.structuredAddressSuggestions?.[0]);
    })
    .catch()
    .finally(() => {
      defaultAddressIsPending.value = false;
    });
  return {
    defaultAddress,
    defaultAddressIsPending,
  };
}
