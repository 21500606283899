<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useEditBillingAccount from "../../composables/edit-billing-account";

import { translateMixin } from "../../locale";

const maxLength = 35;
const t = translateMixin.methods.t;
const { sourceIsMobill } = useDetailPage();
const { editBillingAccountState, updateDeliveryMethodEdiAddress } = useEditBillingAccount();

const submittingForm = computed(() => {
  return editBillingAccountState.value.layout.updateDeliveryMethod.loading;
});

const sourceMobill = computed(() => {
  return sourceIsMobill();
});

const submit = ({ target }) => {
  const reference = target.reference.value;

  updateDeliveryMethodEdiAddress(reference);
};
</script>

<template>
  <div t-id="edit-invoice-edi">
    <telia-notification status="information" heading-tag="h2">
      <span slot="heading" :button-text="t('mybusiness.close')">
        {{ t("drawer.editDeliveryMethodAccount.edi.notification.heading") }}
      </span>
      <telia-text-spacing slot="content">
        <telia-p>
          {{ t("drawer.editDeliveryMethodAccount.edi.notification.description") }}

          <telia-button variant="text" @click="$emit('contact-support')" type="button" size="sm">
            {{ t("drawer.editDeliveryMethodAccount.edi.notification.buttonText") }}
          </telia-button>
        </telia-p>
      </telia-text-spacing>
    </telia-notification>

    <telia-form @submit.prevent="submit" name="delivery-method-edi-form" v-if="sourceMobill">
      <telia-text-input
        label="Referens"
        type="text"
        :additional="t('drawer.shared.form.optionalMaxLength', { 0: maxLength })"
        name="reference"
        :maxlength="maxLength"
        class="reference__input"
      />
      <div class="form__submit--wrapper">
        <b2x-loading-button
          t-id="delivery-method-edi-button"
          variant="primary"
          type="submit"
          :is-loading="submittingForm"
          >{{ t("mybusiness.save_delivery_method") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

div.form__submit--wrapper {
  display: flex;
  align-items: center;
  margin-top: $telia-spacing-48;

  b2x-spinner {
    margin-left: $telia-spacing-12;
  }
}

.reference__input {
  margin-top: $telia-spacing-48;
}

telia-notification {
  padding: $telia-spacing-24;
}
</style>
