<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../composables/detail-page";

import { translateMixin } from "../locale/index";

const t = translateMixin.methods.t;
const { detailPageState } = useDetailPage();

const billingAccountDetails = computed(() => {
  return detailPageState.value.data.billingAccountDetails;
});

const isAccountNumberAndNumberNotTheSame = computed(() => {
  return billingAccountDetails.value.accountNumber !== billingAccountDetails.value.number;
});

const organisationNameAndNumberString = computed(() => {
  return `${billingAccountDetails.value.billingName} (${billingAccountDetails.value.organisationNumber})`;
});

const showSkeleton = computed(() => {
  return (
    detailPageState.value.layout.fetchBillingAccount.loading ||
    detailPageState.value.layout.fetchBillingAccount.error
  );
});
</script>

<template>
  <div class="detail-description-wrapper" t-id="detail-description-component">
    <telia-heading tag="h1" variant="title-400">{{
      t("mybusiness.billing.account")
    }}</telia-heading>
    <template v-if="!showSkeleton">
      <telia-heading tag="h2" variant="title-200"
        >{{ billingAccountDetails.accountNumber }}
        <template v-if="isAccountNumberAndNumberNotTheSame">
          <b2x-tooltip :content="t('tooltip')"></b2x-tooltip>
          ({{ billingAccountDetails.number }})
        </template>
      </telia-heading>
      <telia-p class="padding-top" variant="preamble-100">
        {{ organisationNameAndNumberString }}
      </telia-p>
    </template>
    <template v-if="showSkeleton">
      <telia-skeleton round></telia-skeleton>
      <telia-skeleton round></telia-skeleton>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.detail-description-wrapper {
  telia-skeleton:first-of-type {
    height: 2.9rem;
    width: 40%;
  }

  telia-skeleton:last-of-type {
    padding-top: 0.2rem;
    height: 2.87rem;
    width: 60%;
  }

  b2x-tooltip {
    padding-right: $telia-spacing-12;
  }
}
</style>
