<template>
  <fieldset class="delivery-method" :key="componentRef">
    <legend class="sr-only">{{ t("DELIVERY_METHOD_SR_SELECTION_TITLE") }}</legend>
    <telia-heading class="delivery-method__header" tag="h4">
      {{ t("DELIVERY_METHOD_SELECTION_TITLE") }}
    </telia-heading>
    <div class="delivery-method__container">
      <div
        v-for="deliveryMethod in deliveryMethods"
        :t-id="`delivery-method__option-${deliveryMethod}`"
        class="delivery-method__option"
        :key="deliveryMethod"
      >
        <b2x-radio-card
          name="deliveryMethod"
          :id="deliveryMethod"
          :value="deliveryMethod"
          :label="t(`mybusinessotpcheckout.billingAccountDeliveryMethod${deliveryMethod}`)"
          :checked="deliveryMethod === selectedDeliveryMethod"
          @change.prevent.stop="handleChange(deliveryMethod)"
        ></b2x-radio-card>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import { DeliveryMethods } from "../../utils/constants";
import { DeliveryMethodValues } from "../../typings/types";

const componentRef = ref(0);

loadTranslations();
const t = translateMixin.methods.t;

async function loadTranslations() {
  await translateSetup(["mybusinessotpcheckout"]);
  componentRef.value++;
}

interface Props {
  selectedDeliveryMethod: DeliveryMethodValues;
}

withDefaults(defineProps<Props>(), {
  selectedDeliveryMethod: DeliveryMethods.LETTER,
});

const emit = defineEmits<{
  (e: "change-delivery-method", deliveryMethod: DeliveryMethodValues): void;
}>();
const deliveryMethods = Object.values(DeliveryMethods);

function handleChange(deliveryMethod: DeliveryMethodValues): void {
  emit("change-delivery-method", deliveryMethod);
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.delivery-method {
  margin: 0 0 $telia-spacing-16;
  padding: 0;
  border: 0;

  &__container {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  &__option {
    position: relative;
    flex: 1 1 auto;

    & + & {
      margin-left: $telia-spacing-12;
    }
  }

  &__header {
    margin-bottom: $telia-spacing-16;
  }
}

.sr-only {
  border: 0;
  clip-path: circle(0%);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
