export const DELIVERY_METHODS = {
  EMAIL: "EMAIL",
  POSTAL: "POSTAL",
  EDI: "EDI",
  EDI_AND_LETTER: "EDI_AND_LETTER",
  EINVOICE: "EINVOICE",
} as const;

export const SOURCE = {
  CUSIN: "cusin",
  ALPHA: "alpha",
  RODOD: "rodod",
  BRM: "brm",
} as const;
