<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../../locale";

defineProps<{
  prePopulateMobileNumber?: string;
}>();
const t = translateMixin.methods.t;
const swedishPhoneNumberRegex = "^0(7|8)[0-9]{6,8}";
const internationalPhoneNumberRegex = "^([+]|00)(?:[0-9]?){6,14}[0-9]";

const phoneNumberRegex = computed(() => {
  return `(${swedishPhoneNumberRegex})|(${internationalPhoneNumberRegex})`;
});
</script>

<template>
  <div t-id="mobile-input-field" class="contact-person">
    <telia-text-spacing>
      <telia-heading tag="h2" variant="title-100">{{
        t("mybusiness.contactPerson")
      }}</telia-heading>
      <telia-p variant="paragraph-100">{{
        t("drawer.editDeliveryMethodAccount.email.form.contactPersonText")
      }}</telia-p>
      <telia-text-input
        :label="t('mybusiness.form.assistive.phonenumber')"
        name="phoneNumber"
        type="tel"
        :value="prePopulateMobileNumber"
        :pattern="phoneNumberRegex"
        :pattern-error-message="t('drawer.shared.form.invalidPhoneNumberErrorMessage')"
      ></telia-text-input>
    </telia-text-spacing>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

div.contact-person {
  margin-top: $telia-spacing-48;
}
</style>
