import { ref } from "vue";

import useDetailPage from "./detail-page";
import { translateMixin } from "../locale";

import { IInfoModal } from "../services/interfaces/IState";
import { createInfoModalState } from "../services/consts/create_state";

const translate = translateMixin.methods.t;
const infoModalState = ref<IInfoModal>(createInfoModalState());
const { tryToFetchSubscriptionsList, tryToFetchBillingAccountDetails, clearSelectedSubscriptions } =
  useDetailPage();

const useInfoModal = () => {
  const closeInfoModal = (): void => {
    infoModalState.value.show = false;
    infoModalState.value.header = "";
    infoModalState.value.firstContentRow = "";
    infoModalState.value.secondContentRow = "";
    infoModalState.value.thirdContentRow = "";
    if (infoModalState.value.isMoveSubscription) {
      clearSelectedSubscriptions();
      tryToFetchSubscriptionsList();
    } else {
      tryToFetchBillingAccountDetails();
    }
  };

  const updateInfoModalContentSuccessfulMove = (
    nrOfSubscriptions: string,
    movedToAccountNumber: string
  ): void => {
    infoModalState.value.isMoveSubscription = true;
    _openInfoModal(
      translate("infoModal.move.header"),
      translate("infoModal.move.firstContentRow", {
        nrOfSubscriptions,
        movedToAccountNumber: movedToAccountNumber ?? "",
      }),
      translate("infoModal.move.secondContentRow")
    );
  };

  const updateInfoModalContentPartiallySuccessfulMove = (
    movedToAccountNumber: string,
    failedSubscriptions: string[],
    nrOfSuccessfulSubscriptions: string
  ): void => {
    infoModalState.value.isMoveSubscription = true;

    _openInfoModal(
      translate("infoModal.movePartialSuccess.header"),
      translate("infoModal.movePartialSuccess.firstContentRow", {
        nrOfSuccessfulSubscriptions,
        movedToAccountNumber: movedToAccountNumber ?? "",
      }),
      translate("infoModal.movePartialSuccess.secondContentRow", {
        failedSubscriptions: failedSubscriptions?.join(" "),
      }),
      translate("infoModal.movePartialSuccess.thirdContentRow")
    );
  };

  const updateEditBillingAccountInfoModal = (): void => {
    infoModalState.value.isMoveSubscription = false;
    _openInfoModal(
      translate("infoModal.invoiceDetail.header"),
      translate("infoModal.invoiceDetail.firstContentRow")
    );
  };

  return {
    infoModalState,
    closeInfoModal,
    updateInfoModalContentSuccessfulMove,
    updateInfoModalContentPartiallySuccessfulMove,
    updateEditBillingAccountInfoModal,
  };
};

export default useInfoModal;

const _openInfoModal = (
  header: string,
  firstContentRow: string,
  secondContentRow = "",
  thirdContentRow = ""
): void => {
  infoModalState.value.header = header;
  infoModalState.value.firstContentRow = firstContentRow;
  infoModalState.value.secondContentRow = secondContentRow;
  infoModalState.value.thirdContentRow = thirdContentRow;
  infoModalState.value.show = true;
};
