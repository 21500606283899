<script setup lang="ts">
import { translateMixin } from "../../locale";
import { applyPureReactInVue } from "veaury";
import { TextSpacing, Heading, Paragraph } from "@purpurds/purpur";

const t = translateMixin.methods.t;
const PurpurTextSpacing = applyPureReactInVue(TextSpacing);
const PurpurHeading = applyPureReactInVue(Heading);
const PurpurParagraph = applyPureReactInVue(Paragraph);
</script>

<template>
  <div>
    <PurpurTextSpacing>
      <PurpurHeading tag="h2" variant="title-100">
        {{ t("drawer.moveSubscriptions.message.missingAccountNotification.heading") }}
      </PurpurHeading>
      <PurpurParagraph variant="paragraph-100">
        {{ t("drawer.moveSubscriptions.message.missingAccountNotification.description") }}
      </PurpurParagraph>
    </PurpurTextSpacing>
  </div>
</template>
