<script setup lang="ts">
import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
</script>

<template>
  <telia-notification status="information" heading-tag="h2">
    <span slot="heading" t-id="delivery-method-postal-info-heading">
      {{ t("drawer.moveSubscriptions.message.notification.heading") }}
    </span>
    <telia-text-spacing slot="content" t-id="delivery-method-postal-info-description">
      <telia-p>
        {{ t("drawer.moveSubscriptions.message.notification.description") }}
      </telia-p>
    </telia-text-spacing>
  </telia-notification>
</template>
