<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import useDetailPage from "../composables/detail-page";
import useMoveSubscriptions from "../composables/move-subscriptions";

import { translateMixin } from "../locale/index";

import detailClearSelectedSubscriptions from "./detail-clear-selected-subscriptions.vue";

const t = translateMixin.methods.t;
const leftIcon = ref(JSON.stringify({ name: "sorter", size: "sm" }));
const { detailPageState } = useDetailPage();
const { initialize, openMoveSubscriptionDrawer } = useMoveSubscriptions();

onMounted(() => {
  initialize(
    detailPageState.value.data.loggedInUser.scopeId,
    detailPageState.value.data.organisationTscid,
    detailPageState.value.data.billingAccountDetails.accountNumber,
    detailPageState.value.data.source
  );
});

const selectedSubscriptions = computed(() => {
  return detailPageState.value.data.selectedSubscriptions;
});

const moveSubscriptionButtonText = computed(() => {
  return `${t("mybusiness.moveSubscriptions")} (${selectedSubscriptions.value.length}/10)`;
});

const moveButtonDisabled = computed(() => {
  return selectedSubscriptions.value.length === 0 || selectedSubscriptions.value.length > 10;
});

const showClearSelectedSubscriptionsButton = computed(() => {
  return selectedSubscriptions.value.length > 0;
});

const handleOpenMoveSubscriptionDrawer = () => {
  openMoveSubscriptionDrawer(detailPageState.value.data.selectedSubscriptions);
};
</script>

<template>
  <div class="detail-move-subscription-wrapper">
    <telia-button
      :ally-label="t('mybusiness.moveSubscriptions')"
      t-id="move-subscription-button"
      :disabled="moveButtonDisabled"
      variant="tertiary-purple"
      :text="moveSubscriptionButtonText"
      :left-icon="leftIcon"
      type="button"
      @click="handleOpenMoveSubscriptionDrawer"
    >
    </telia-button>
    <detail-clear-selected-subscriptions v-if="showClearSelectedSubscriptionsButton" />
  </div>
</template>

<style lang="scss" scoped>
.detail-move-subscription-wrapper {
  min-height: 5em;

  telia-button :deep(telia-icon) {
    transform: rotate(90deg);
  }
}
</style>
