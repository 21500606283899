<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useEditBillingAccount from "../../composables/edit-billing-account";

import BillingAccountAddressFormInputFields from "../form-fields/billing-account-address-form-input-fields.vue";

import { translateMixin } from "../../locale";

import { DELIVERY_METHODS, SOURCE } from "../../services/interfaces/constants";

import { trackChangedCo, trackChangedReference } from "../../utils/trackGA4";

const t = translateMixin.methods.t;
const { detailPageState, sourceIsAlpha, deliveryTypeIsEdi } = useDetailPage();
const { editBillingAccountState, updateBillingAddress } = useEditBillingAccount();

const submittingForm = computed(() => {
  return editBillingAccountState.value.layout.updateBillingAddress.loading;
});

const billingAccountDetails = computed(() => {
  return detailPageState.value.data.billingAccountDetails;
});

const city = computed(() => {
  return billingAccountDetails.value.city;
});

const zip = computed(() => {
  return billingAccountDetails.value.zip;
});

const street = computed(() => {
  return billingAccountDetails.value.street;
});

const accountReference = computed(() => {
  return billingAccountDetails.value.accountReference;
});

const accountCO = computed(() => {
  return billingAccountDetails.value.accountCO;
});

const showAccountReferenceField = computed(() => {
  return (
    (billingAccountDetails.value.isMobileBillingAccount &&
      billingAccountDetails.value.invoiceDeliveryType !== DELIVERY_METHODS.EDI) ||
    detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.BRM
  );
});

const showAccountCOField = computed(() => {
  return (
    detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.BRM ||
    detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.ALPHA
  );
});

const accountCOFieldLabel = computed(() => {
  if (deliveryTypeIsEdi()) {
    return sourceIsAlpha() ? t("mybusiness.edi_reference") : t("mybusiness.careOf.address");
  }
  return t("mybusiness.invoice.organisation.accountCO");
});

const accountReferenceFieldLabel = computed(() => {
  return t("mybusiness.invoice.organisation.accountReference");
});

const submit = ({ target }) => {
  const reference = target.accountReference?.value || "";
  const co = target.accountCO?.value || "";
  const city = target.city.value;
  const postalCode = target.zip.value.trim().replace(" ", "");
  const streetAddress = target.street.value;
  const deliveryMethod = getCurrentDeliveryMethod();

  if (accountCO.value !== co) {
    trackChangedCo();
  }

  if (accountReference.value !== reference) {
    trackChangedReference();
  }

  const updateBillingAddressRequest = {
    city,
    postalCode,
    reference,
    co,
    streetAddress,
    deliveryMethod,
  };

  updateBillingAddress(updateBillingAddressRequest);
};

const getCurrentDeliveryMethod = (): string => {
  return editBillingAccountState.value.data.updatedData.deliveryMethod
    ? editBillingAccountState.value.data.updatedData.deliveryMethod
    : detailPageState.value.data.billingAccountDetails.invoiceDeliveryType.toLocaleLowerCase();
};
</script>

<template>
  <telia-accordion-item :heading="t('mybusiness.change_account_details')" heading-tag="h2">
    <telia-p class="info-text" t-id="correct-billing-address-text">
      {{ t("drawer.editBillingAccount.correctBillingAddress") }}</telia-p
    >
    <telia-form @submit.prevent="submit" name="updateAddressDetails">
      <billing-account-address-form-input-fields
        class="wrapper"
        :accountReferenceValue="accountReference"
        :accountCOValue="accountCO"
        :cityValue="city"
        :streetValue="street"
        :zipValue="zip"
        :showAccountReferenceField="showAccountReferenceField"
        :showAccountCOField="showAccountCOField"
        :accountReferenceFieldLabel="accountReferenceFieldLabel"
        :accountCOFieldLabel="accountCOFieldLabel"
      />
      <div class="form__submit--wrapper">
        <b2x-loading-button
          t-id="submit-button"
          variant="primary"
          type="submit"
          :is-loading="submittingForm"
          >{{ t("mybusiness.save_account_details") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </telia-accordion-item>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables";
@import "@teliads/components/foundations/spacing/variables";

telia-accordion-item {
  --vds-accordion-item-content-max-width: 100%;
}

.info-text {
  padding-bottom: $telia-spacing-12;
}

.wrapper {
  padding: $telia-spacing-12 $telia-spacing-12 $telia-spacing-24 $telia-spacing-24;
  background-color: $telia-gray-50;
}

div.form__submit--wrapper {
  display: flex;
  align-items: center;
  margin-top: $telia-spacing-48;

  b2x-spinner {
    margin-left: $telia-spacing-12;
  }
}
</style>
