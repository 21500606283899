import { ref } from "vue";

import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { ApiError } from "@telia/b2b-rest-client/dist/corp-customer-billing-accounts";
import { GetOrganizationResponse } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";
import { logError } from "@telia/b2x-logging";

import { detailPageServiceCreateState } from "../services/consts/create_state";
import {
  fetchBillingAccount,
  fetchSubscriptionsList,
  getBillingAccountSource,
} from "../services/corpCustomerBillingAccounts";
import { IState, ISubscription } from "../services/interfaces/IState";
import { DELIVERY_METHODS, SOURCE } from "../services/interfaces/constants";

const detailPageState = ref<IState>(detailPageServiceCreateState());

const analytics = ref({
  action,
  category,
  label,
});

const useDetailPage = () => {
  const initialize = () => {
    _initializeData();
  };

  const filterTableData = (filterValue: string): void => {
    detailPageState.value.data.filterValue = filterValue;
    detailPageState.value.data.pagination.page = 1;
    _generateTableData(true);
  };

  const checkboxClicked = (data: number | string): void => {
    if (typeof data === "string" && data === "CHECKBOX_ALL") {
      _handleAllCheckBoxesClicked();
    } else {
      _handleRowCheckBoxClicked(data as number);
    }
  };

  const clearSelectedSubscriptions = (): void => {
    _clearSelectedSubscriptions();
  };

  const deliveryTypeIsEdi = (): boolean => {
    return (
      detailPageState.value.data.billingAccountDetails.invoiceDeliveryType === DELIVERY_METHODS.EDI
    );
  };

  const deliveryTypeIsEinvoice = (): boolean => {
    return (
      detailPageState.value.data.billingAccountDetails.invoiceDeliveryType ===
      DELIVERY_METHODS.EINVOICE
    );
  };

  const deliveryTypeIsPostal = (): boolean => {
    return (
      detailPageState.value.data.billingAccountDetails.invoiceDeliveryType ===
      DELIVERY_METHODS.POSTAL
    );
  };

  const deliveryTypeIsEmail = (): boolean => {
    return (
      detailPageState.value.data.billingAccountDetails.invoiceDeliveryType ===
      DELIVERY_METHODS.EMAIL
    );
  };

  const sourceIsAlpha = (): boolean => {
    return detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.ALPHA;
  };

  const sourceIsSiebel = (): boolean => {
    return detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.RODOD;
  };

  const sourceIsMobill = (): boolean => {
    return detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.CUSIN;
  };

  const sourceIsBRM = (): boolean => {
    return detailPageState.value.data.source.toLocaleLowerCase() === SOURCE.BRM;
  };

  const paginationChange = (detail: { page: number; pageSize: number }): void => {
    _paginationChangeGoogleAnalytics(detail.page, detail.pageSize);
    _changePage(detail.page, detail.pageSize);
  };

  const tryToFetchBillingAccountDetails = () => {
    _tryToFetchBillingAccountDetails();
  };
  const tryToFetchSubscriptionsList = () => {
    _tryToFetchSubscriptionsList();
  };

  return {
    detailPageState,
    initialize,
    filterTableData,
    checkboxClicked,
    clearSelectedSubscriptions,
    deliveryTypeIsEdi,
    deliveryTypeIsEinvoice,
    deliveryTypeIsPostal,
    deliveryTypeIsEmail,
    sourceIsAlpha,
    sourceIsSiebel,
    sourceIsMobill,
    sourceIsBRM,
    paginationChange,
    tryToFetchBillingAccountDetails,
    tryToFetchSubscriptionsList,
  };
};

export default useDetailPage;

const _handleRowCheckBoxClicked = (rowNumber: number): void => {
  const clickedRowSubscription = detailPageState.value.data.tableData[rowNumber];

  _subscriptionIsSelected(clickedRowSubscription)
    ? _removeSubscriptionFromSelectedSubscriptions(clickedRowSubscription)
    : _addSubscriptionToSelectedSubscriptions(clickedRowSubscription);
};

const _handleAllCheckBoxesClicked = (): void => {
  _areAllSubscriptionsSelected()
    ? _clearSelectedSubscriptions()
    : _addAllSubscriptionsToSelectedSubscriptions();
};

const _addSubscriptionToSelectedSubscriptions = (clickedRowSubscription: ISubscription): void => {
  detailPageState.value.data.selectedSubscriptions.push(clickedRowSubscription);
};

const _removeSubscriptionFromSelectedSubscriptions = (
  clickedRowSubscription: ISubscription
): void => {
  detailPageState.value.data.selectedSubscriptions =
    detailPageState.value.data.selectedSubscriptions.filter(
      (sub) => sub !== clickedRowSubscription
    );
};

const _subscriptionIsSelected = (clickedRowSubscription: ISubscription): boolean => {
  return detailPageState.value.data.selectedSubscriptions.includes(clickedRowSubscription);
};

const _areAllSubscriptionsSelected = (): boolean => {
  return (
    detailPageState.value.data.selectedSubscriptions.length ===
    detailPageState.value.data.tableData.length
  );
};

const _addAllSubscriptionsToSelectedSubscriptions = (): void => {
  detailPageState.value.data.selectedSubscriptions = [...detailPageState.value.data.tableData];
};

const _clearSelectedSubscriptions = (): void => {
  detailPageState.value.data.selectedSubscriptions = [];
};

const _initializeData = async (): Promise<void> => {
  _clearSelectedSubscriptions();
  await _setLoggedInUserScopeID();
  const { organisation, accNr } = _getOrganisationAndAccNrFromUrl();
  detailPageState.value.data.billingAccountDetails.accountNumber = accNr;
  await _fetchTscidForOrganisation(organisation);
  await _getBillingAccountSource(accNr);
  await _tryToFetchBillingAccountDetails();
  await _tryToFetchSubscriptionsList();
  _generateTableData();
};

const _setLoggedInUserScopeID = async (): Promise<void> => {
  detailPageState.value.data.loggedInUser.scopeId = await getScopeIdOrThrow();
};

const _tryToFetchBillingAccountDetails = async (): Promise<void> => {
  detailPageState.value.layout.fetchBillingAccount.notFound = false;
  detailPageState.value.layout.fetchBillingAccount.error = false;

  try {
    detailPageState.value.layout.fetchBillingAccount.loading = true;
    detailPageState.value.data.billingAccountDetails = await fetchBillingAccount(
      detailPageState.value.data.loggedInUser.scopeId,
      detailPageState.value.data.organisationTscid,
      detailPageState.value.data.billingAccountDetails.accountNumber,
      detailPageState.value.data.source
    );
  } catch (error) {
    if ((error as ApiError).status === 404 || (error as any).status === "404") {
      detailPageState.value.layout.fetchBillingAccount.notFound = true;
      _logError("No billing account found");
    } else {
      detailPageState.value.layout.fetchBillingAccount.error = true;
      _logError("Failed to fetch billing account");
    }
  } finally {
    detailPageState.value.layout.fetchBillingAccount.loading = false;
  }
};

const _tryToFetchSubscriptionsList = async (): Promise<void> => {
  try {
    detailPageState.value.layout.fetchSubscriptionList.loading = true;
    detailPageState.value.layout.fetchSubscriptionList.error = false;
    detailPageState.value.data.accountSubscriptions = (await fetchSubscriptionsList(
      detailPageState.value.data.loggedInUser.scopeId,
      detailPageState.value.data.organisationTscid,
      detailPageState.value.data.billingAccountDetails.accountNumber,
      detailPageState.value.data.source
    )) as ISubscription[];
  } catch (error) {
    detailPageState.value.layout.fetchSubscriptionList.error = true;
    _logError("Failed to fetch subscriptions");
  } finally {
    detailPageState.value.layout.fetchSubscriptionList.loading = false;
  }
};

const _getOrganisationAndAccNrFromUrl = (): Record<string, string> => {
  const pathnameSplitToArray = window.location.pathname.split("/");
  const accNr: string = pathnameSplitToArray.pop() as string;
  const organisation: string = pathnameSplitToArray.pop() as string;

  return { organisation, accNr };
};

const _getBillingAccountSource = async (accNr: string): Promise<void> => {
  let source = new URLSearchParams(window.location.search).get("source") || "";
  if (!source) {
    detailPageState.value.layout.fetchBillingAccount.notFound = false;
    detailPageState.value.layout.fetchBillingAccount.error = false;

    await getBillingAccountSource(
      detailPageState.value.data.loggedInUser.scopeId,
      detailPageState.value.data.organisationTscid,
      accNr
    )
      .then((response) => {
        source = response.source || "";
      })
      .catch(({ error, status }) => {
        if (status == 404 || status == "404") {
          detailPageState.value.layout.fetchBillingAccount.notFound = true;
          _logError("Billing account not found");
        } else {
          detailPageState.value.layout.fetchBillingAccount.sourceError = true;
          _logError("Failed to fetch billing account source");
        }
        throw error;
      });
  }
  detailPageState.value.data.source = source;
};

const _fetchTscidForOrganisation = async (organisation: string): Promise<void> => {
  const organisations = await getOrganizations();

  detailPageState.value.data.organisationTscid =
    organisations.find(
      (org: GetOrganizationResponse) =>
        org.organizationNumber?.replace("-", "") === organisation || org.tscid === organisation
    )?.tscid ?? "";
};

const _generateTableData = (sendGaForFiltering = false): void => {
  detailPageState.value.data.tableData = _filterPaginateAndMapTableData(sendGaForFiltering);
};

const _filterPaginateAndMapTableData = (sendGaForFiltering = false): ISubscription[] => {
  const subscriptions = detailPageState.value.data.accountSubscriptions;
  const subscriptionsFiltered = _filterSubscriptions(subscriptions);

  if (sendGaForFiltering) {
    const hits = subscriptionsFiltered.length === 0 ? "0" : "1";
    _trackEvent(hits, analytics.value.action.FILTER);
  }

  const subscriptionsFilteredAndPaginated = _filterOnPaginationValues(subscriptionsFiltered);

  detailPageState.value.data.pagination.total = subscriptionsFiltered.length;

  return subscriptionsFilteredAndPaginated;
};

const _filterSubscriptions = (subscriptions: ISubscription[]): ISubscription[] => {
  const filterValue: string = detailPageState.value.data.filterValue;

  return subscriptions.filter(
    (subscription: ISubscription) =>
      subscription.offeringName.toLowerCase().includes(filterValue.toLowerCase()) ||
      subscription.user.toLowerCase().includes(filterValue.toLowerCase()) ||
      subscription.subscriptionId.toLowerCase().includes(filterValue.toLowerCase())
  );
};

const _filterOnPaginationValues = (subscriptions: ISubscription[]): ISubscription[] => {
  const getSubscriptionFrom = _calculateSubscriptionFrom();
  const getSubscriptionTo = _calculateSubscriptionTo();
  return subscriptions.filter(
    (subscription: ISubscription, index: number) =>
      index >= getSubscriptionFrom && index < getSubscriptionTo
  );
};

const _changePage = (page: number, pageSize: number): void => {
  detailPageState.value.data.pagination.page = page;
  detailPageState.value.data.pagination.pageSize = pageSize;
  _generateTableData();
};

const _calculateSubscriptionFrom = (): number => {
  const endSubscriptionNumber = _calculateSubscriptionTo();
  const numberOfSubscriptions = detailPageState.value.data.pagination.pageSize;
  const startSubscriptionFrom = endSubscriptionNumber - numberOfSubscriptions;
  return startSubscriptionFrom;
};

const _calculateSubscriptionTo = (): number => {
  const endSubscriptionNumber =
    detailPageState.value.data.pagination.pageSize * detailPageState.value.data.pagination.page;
  return endSubscriptionNumber;
};

const _trackEvent = (label: string, action: string): void => {
  const category = analytics.value.category.INVOICE_ACCOUNT_DETAILS;
  trackEvent(category, action, label);
};

const _paginationChangeGoogleAnalytics = (page: number, pageSize: number): void => {
  const label = _pageSizeHasChanged(pageSize)
    ? `Page size selected ${pageSize}`
    : `Page selected ${page}`;
  _trackEvent(label, analytics.value.action.CLICK);
};

const _pageSizeHasChanged = (newpageSize: number): boolean => {
  return newpageSize !== detailPageState.value.data.pagination.pageSize;
};

const _logError = (message: string): void => {
  logError("b2b-billing-account-detail-page", message);
};
