import { trackGa4, getGa4DefaultParams, getGa4StepParams } from "@telia/b2b-analytics";

const sharedValues = {
  ...getGa4DefaultParams(),
  logged_in: true,
  page_type: "invoice",
};

export const trackOpenDrawer = () => {
  trackGa4("invoice_configuration_initiate", {
    ...sharedValues,
  });
};

export const trackToUpdateBillingAddress = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_address_change"),
  });
};

export const trackUpdateDeliveryMethodEmailAddress = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_delivery_change", undefined, "email"),
  });
};

export const trackUpdateDeliveryMethodPostal = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_delivery_change", undefined, "post"),
  });
};

export const trackUpdateDeliveryMethodEdiAddress = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_delivery_change", undefined, "edi"),
  });
};

export const trackChangedReference = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_reference_change"),
  });
};

export const trackChangedCo = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_co-address_change"),
  });
};

export const trackChangedGroup = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("invoice_group_change"),
  });
};

export const trackChangeAccountToExisting = () => {
  trackGa4("invoice_configuration_progress", {
    ...sharedValues,
    ...getGa4StepParams("billing_account_change", undefined, "existing_account"),
  });
};

export const trackChangeAccountToNew = () => {
  trackGa4("invoice_configuration_progress", {
    ...sharedValues,
    ...getGa4StepParams("billing_account_change", undefined, "new_account"),
  });
};

export const trackChangeAccountToNewComplete = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("billing_account_change", undefined, "new_account"),
  });
};

export const trackChangeAccountToExistingComplete = () => {
  trackGa4("invoice_configuration_complete", {
    ...sharedValues,
    ...getGa4StepParams("billing_account_change", undefined, "existing_account"),
  });
};

export const trackChangedGroupErrorToNew = (errorMessage: string) => {
  trackGa4("invoice_configuration_fail", {
    ...sharedValues,
    ...getGa4StepParams(undefined, undefined, "new_account"),
    error_type: "billing_account_change",
    error_message: errorMessage,
  });
};

export const trackChangedGroupErrorToExisting = (errorMessage: string) => {
  trackGa4("invoice_configuration_fail", {
    ...sharedValues,
    ...getGa4StepParams(undefined, undefined, "existing_account"),
    error_type: "billing_account_change",
    error_message: errorMessage,
  });
};
