import { DeliveryMethods } from "./constants";
import type { DeliveryMethodValues } from "../typings/types";
import { LegalAddressResponseDTO } from "@telia/b2b-rest-client/dist/corp-address-lookup";

export const isValidOrganizationNumberPattern = new RegExp(
  /^(?:[\d]{10}|[\d]{12}|(?:(?:[\d]{6}|[\d]{8})-[\d]{4}))$/
);

export const isValidMobileNumberPattern = new RegExp(/^(?:\+46|0046|0)7[-\d\s]{5,18}\d$/);

export const isValidEmailPattern = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/);

export function formIsValid(
  deliveryMethod: DeliveryMethodValues,
  reference: string,
  address: LegalAddressResponseDTO | null,
  emailAddress: string
): boolean {
  if (deliveryMethod === DeliveryMethods.LETTER) {
    return referenceAndAddressIsValid(reference, address);
  } else if (deliveryMethod === DeliveryMethods.EMAIL) {
    return referenceAndAddressIsValid(reference, address) && emailIsValid(emailAddress);
  }
  return false;
}

export function referenceAndAddressIsValid(reference: string, address): boolean {
  return reference !== "" && address !== null;
}

export function emailIsValid(email: string): boolean {
  const emailRegex = isValidEmailPattern;
  return emailRegex.test(email);
}
