<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../composables/detail-page";
import useEditBillingAccount from "../composables/edit-billing-account";

import { translateMixin } from "../locale/index";

const t = translateMixin.methods.t;
const { detailPageState, sourceIsSiebel, deliveryTypeIsEinvoice } = useDetailPage();
const { openEditBillingAccountDrawer } = useEditBillingAccount();

const isLoading = computed(() => {
  return (
    detailPageState.value.layout.fetchBillingAccount.loading ||
    detailPageState.value.layout.fetchBillingAccount.error
  );
});

const billingAccountDetails = computed(() => {
  return detailPageState.value.data.billingAccountDetails;
});

const showDeliveryEmail = computed(() => {
  return billingAccountDetails.value.invoiceDeliveryEmail !== "";
});

const showDeliveryPhoneNumber = computed(() => {
  return billingAccountDetails.value.phoneNumber !== "";
});

const showReference = computed(() => {
  return billingAccountDetails.value.accountReference !== "";
});

const showCO = computed(() => {
  return billingAccountDetails.value.accountCO !== "";
});

const showEditButton = computed(() => {
  return !sourceIsSiebel() && !deliveryTypeIsEinvoice();
});
</script>

<template>
  <section class="detail-card-wrapper" t-id="detail-card-component">
    <b2x-card background-color="light">
      <b2x-property-table-header-skeleton
        v-if="isLoading"
        :display-auxiliary-button="showEditButton"
      ></b2x-property-table-header-skeleton>
      <b2x-property-table-skeleton v-if="isLoading"></b2x-property-table-skeleton>

      <b2x-property-table-header t-id="detail-card-header" v-if="!isLoading">
        <telia-heading variant="title-100" tag="h1" slot="heading">{{
          t("mybusiness.billing.account.details")
        }}</telia-heading>
        <telia-button
          v-if="showEditButton"
          slot="auxiliary"
          variant="tertiary-purple"
          @click="openEditBillingAccountDrawer"
          >{{ t("mybusiness.edit") }}</telia-button
        >
      </b2x-property-table-header>

      <b2x-property-table v-if="!isLoading">
        <b2x-property-row>
          <b2x-property-label-column slot="label">{{
            t("mybusiness.invoice.address")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            billingAccountDetails.billingAddress
          }}</b2x-property-value-column>
        </b2x-property-row>

        <b2x-property-row v-if="showReference">
          <b2x-property-label-column slot="label">{{
            t("mybusiness.invoice.organisation.accountReference")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            billingAccountDetails.accountReference
          }}</b2x-property-value-column>
        </b2x-property-row>

        <b2x-property-row v-if="showCO">
          <b2x-property-label-column slot="label">{{
            t("mybusiness.invoice.organisation.accountCO")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            billingAccountDetails.accountCO
          }}</b2x-property-value-column>
        </b2x-property-row>

        <b2x-property-row>
          <b2x-property-label-column slot="label">{{
            t("mybusiness.invoice.deliveryMethod")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            t(billingAccountDetails.invoiceDeliveryMethodKey)
          }}</b2x-property-value-column>
        </b2x-property-row>

        <b2x-property-row v-if="showDeliveryEmail">
          <b2x-property-label-column slot="label">{{
            t("mybusiness.invoice.deliveryMethod.deliveryEmail")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            billingAccountDetails.invoiceDeliveryEmail
          }}</b2x-property-value-column>
        </b2x-property-row>

        <b2x-property-row v-if="showDeliveryPhoneNumber">
          <b2x-property-label-column slot="label">{{
            t("mybusiness.form.assistive.phonenumber")
          }}</b2x-property-label-column>

          <b2x-property-value-column slot="value">{{
            billingAccountDetails.phoneNumber
          }}</b2x-property-value-column>
        </b2x-property-row>
      </b2x-property-table>
    </b2x-card>
  </section>
</template>

<style lang="scss" scoped></style>
