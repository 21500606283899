<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useMoveSubscriptions from "../../composables/move-subscriptions";

import billingAccountAddressFormInputFields from "../form-fields/billing-account-address-form-input-fields.vue";

import { translateMixin } from "../../locale";
import { SOURCE } from "../../services/interfaces/constants";

import MoveSubscriptionInformation from "./move-subscription-information.vue";

const t = translateMixin.methods.t;
const { detailPageState } = useDetailPage();
const { moveSubscriptionsState, closeMoveSubscriptionsNotification, moveToNewMobillAccount } =
  useMoveSubscriptions();

const showNotification = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.error;
});

const showMobileAccountsInformation = computed(() => {
  return moveSubscriptionsState.value.data.source.toLowerCase() === SOURCE.CUSIN;
});

const submittingForm = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.loading;
});

const submit = ({ target }) => {
  const accountReference = target.accountReference.value;
  const city = target.city.value;
  const zip = target.zip.value.trim().replace(" ", "");
  const street = target.street.value;
  const name = detailPageState.value.data.billingAccountDetails.billingName;

  const subscriptionIds = moveSubscriptionsState.value.data.selectedSubscriptions.map(
    (sub) => sub.subscriptionId
  );
  const currentBillingAccountNumber = moveSubscriptionsState.value.data.accountNumber;

  moveToNewMobillAccount(
    currentBillingAccountNumber,
    subscriptionIds,
    accountReference,
    city,
    zip,
    street,
    name
  );
};

const closeNotification = () => {
  closeMoveSubscriptionsNotification("newAccount");
};
</script>

<template>
  <div class="new-billing-account-content" t-id="move-subscription-drawer-radio-button-2">
    <telia-form @submit.prevent="submit" name="moveToNewAccountForm">
      <billing-account-address-form-input-fields
        :accountReferenceFieldLabel="t('mybusiness.invoice.organisation.accountReference')"
        :show-account-c-o-field="false"
      />
      <div v-if="showMobileAccountsInformation" class="information">
        <move-subscription-information
          t-id="move-subscription-information"
        ></move-subscription-information>
      </div>
      <Transition name="notification">
        <div class="notification" v-if="showNotification" t-id="move-subscription-notification">
          <telia-notification
            status="error"
            :button-text="t('mybusiness.close')"
            :button-aria-label="t('drawer.moveSubscriptions.message.ariaLabels.button')"
            heading-tag="h2"
            @vocaClose="closeNotification"
          >
            <span slot="heading">{{
              t("drawer.moveSubscriptions.message.errorMoving.header")
            }}</span>
            <telia-text-spacing slot="content">
              <telia-p>{{ t("drawer.moveSubscriptions.message.errorMoving.content") }}</telia-p>
            </telia-text-spacing>
          </telia-notification>
        </div>
      </Transition>
      <div class="form__submit--wrapper">
        <b2x-loading-button variant="primary" type="submit" :is-loading="submittingForm"
          >{{ t("mybusiness.move") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.new-billing-account-content {
  div.information {
    margin-top: $telia-spacing-16;
  }
  div.notification {
    margin-bottom: $telia-spacing-12;
  }

  .notification-enter-active,
  .notification-leave-active {
    transition: opacity 1.5s ease;
  }

  .notification-enter-from,
  .notification-leave-to {
    opacity: 0;
  }

  div.form__submit--wrapper {
    display: flex;
    align-items: center;
    padding-top: $telia-spacing-32;

    b2x-spinner {
      margin-left: $telia-spacing-12;
    }
  }
}
</style>
