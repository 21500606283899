<script setup lang="ts">
import { ref, computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useMoveSubscriptions from "../../composables/move-subscriptions";

import { translateMixin } from "../../locale";
import { SOURCE } from "../../services/interfaces/constants";

import MoveSubscriptionInformation from "./move-subscription-information.vue";

import { IBillingAccount } from "../../services/interfaces/IMoveSubscriptionState";
import MoveSubscriptionMissingAccountInformation from "./move-subscription-missing-account-information.vue";
import { useFlag } from "@unleash/proxy-client-vue";

const t = translateMixin.methods.t;
const { sourceIsBRM } = useDetailPage();
const isBrmEnabled = useFlag("b2b-move-brm-billing-accounts");
const suggestions = ref<string[]>([]);
const inputValue = ref("");
const suggestionSelected = ref<IBillingAccount | undefined>(undefined);
const gaEventTracked = ref(false);
const {
  moveSubscriptionsState,
  findSuggestion,
  closeMoveSubscriptionsNotification,
  moveSubscription,
  getSuggestionsForBillingAccounts,
} = useMoveSubscriptions();

const moveSubscriptionsLoading = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.loading;
});

const isButtonDisabled = computed(() => {
  return !suggestionSelected.value || moveSubscriptionsLoading.value;
});

const fetchbillingAccountsLoading = computed(() => {
  return moveSubscriptionsState.value.layout.fetchBillingAccounts.loading;
});

const showNotification = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.error;
});

const showMobileAccountsInformation = computed(() => {
  return moveSubscriptionsState.value.data.source.toLowerCase() === SOURCE.CUSIN;
});

const submittingForm = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.loading;
});

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  setSuggestions();
};

const setSuggestions = () => {
  suggestions.value = getSuggestionsForBillingAccounts(inputValue.value, gaEventTracked.value);
  gaEventTracked.value = true;
};

const clearSuggestions = () => {
  suggestions.value = [];
};

const selectSuggestion = (event: CustomEvent) => {
  inputValue.value = event.detail;
  clearSuggestions();
  suggestionSelected.value = findSuggestion(inputValue.value);
};

const submit = () => {
  if (!suggestionSelected.value?.accountNumber) return;
  moveSubscription(suggestionSelected.value.accountNumber).then((response) => {
    if (response) {
      clearInputField();
    }
  });
};

const clearInputField = () => {
  inputValue.value = "";
};

const closeNotification = () => {
  closeMoveSubscriptionsNotification("existingAccount");
};

const isBRM = computed(() => {
  return isBrmEnabled.value && sourceIsBRM();
});
</script>

<template>
  <div class="existing-billing-account-content" t-id="move-subscription-drawer-radio-button-1">
    <telia-form @submit.prevent="submit" name="moveToExistingAccountForm">
      <b2x-input-suggestions
        @inputValue="handleInputValue"
        @suggestionSelected="selectSuggestion"
        :loading="fetchbillingAccountsLoading"
        :suggestions="JSON.stringify(suggestions)"
        :value="inputValue"
        :label="t('drawer.moveSubscriptions.inputFieldLabel')"
        :disabled="moveSubscriptionsLoading"
        @focus="setSuggestions"
        @blur="clearSuggestions"
      ></b2x-input-suggestions>
      <div class="notification">
        <move-subscription-information
          v-if="showMobileAccountsInformation"
          t-id="move-subscription-information"
        ></move-subscription-information>
        <move-subscription-missing-account-information
          class="move-subscription-missing-account-information"
          t-id="move-subscription-missing-account-information"
          v-if="isBRM"
        ></move-subscription-missing-account-information>
      </div>
      <Transition name="notification">
        <div class="notification" v-if="showNotification" t-id="move-subscription-notification">
          <telia-notification
            status="error"
            :button-text="t('mybusiness.close')"
            :button-aria-label="t('drawer.moveSubscriptions.message.ariaLabels.button')"
            heading-tag="h2"
            @vocaClose="closeNotification"
            t-id="move-subscription-drawer-radio-button-1-notification"
          >
            <span slot="heading">{{
              t("drawer.moveSubscriptions.message.errorMoving.header")
            }}</span>
            <telia-text-spacing slot="content">
              <telia-p>{{ t("drawer.moveSubscriptions.message.errorMoving.content") }}</telia-p>
            </telia-text-spacing>
          </telia-notification>
        </div>
      </Transition>
      <div class="form__submit--wrapper">
        <b2x-loading-button
          variant="primary"
          type="submit"
          :is-loading="submittingForm"
          :disabled="isButtonDisabled"
          >{{ t("mybusiness.move") }}
        </b2x-loading-button>
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.existing-billing-account-content {
  div.form__submit--wrapper {
    display: flex;
    align-items: center;
    padding-top: $telia-spacing-24;

    b2x-spinner {
      margin-left: $telia-spacing-12;
    }
  }

  div.notification {
    margin-top: $telia-spacing-24;
  }

  .notification-enter-active,
  .notification-leave-active {
    transition: opacity 1.5s ease;
  }

  .notification-enter-from,
  .notification-leave-to {
    opacity: 0;
  }
}
</style>
