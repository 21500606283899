<script setup lang="ts">
import { computed } from "vue";

import useInfoModal from "../composables/info-modal";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { infoModalState, closeInfoModal } = useInfoModal();

const showModal = computed(() => {
  return infoModalState.value.show;
});

const header = computed(() => {
  return infoModalState.value.header;
});

const firstContentRow = computed(() => {
  return infoModalState.value.firstContentRow;
});

const secondContentRow = computed(() => {
  return infoModalState.value.secondContentRow;
});

const thirdContentRow = computed(() => {
  return infoModalState.value.thirdContentRow;
});

const closeModal = () => {
  closeInfoModal();
};

const showAdditionalContentRow = (rowText: string) => {
  return rowText !== "";
};
</script>

<template>
  <b2x-modal
    modal-id="detail-info-modal"
    @closeModal="closeModal"
    :is-open="showModal"
    t-id="detail-info-modal"
  >
    <div slot="modal-body">
      <telia-heading variant="title-300" tag="h3" t-id="detail-info-modal-heading">{{
        header
      }}</telia-heading>
      <telia-p t-id="detail-info-modal-first-content">{{ firstContentRow }}</telia-p>
      <telia-p
        v-if="showAdditionalContentRow(secondContentRow)"
        class="second-content-row"
        :note-text="t('infoModal.move.note')"
        t-id="detail-info-modal-second-content"
      >
        {{ secondContentRow }}
      </telia-p>
      <telia-p
        v-if="showAdditionalContentRow(thirdContentRow)"
        t-id="detail-info-modal-third-content"
      >
        {{ thirdContentRow }}
      </telia-p>
    </div>
    <div slot="button-footer" class="button-footer">
      <telia-button variant="primary" @click="closeModal" t-id="detail-info-modal-button">{{
        t("infoModal.button")
      }}</telia-button>
    </div>
  </b2x-modal>
</template>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/typography/variables.scss";
@import "@teliads/components/foundations/typography/mixins.scss";

div.modal-body {
  padding: $telia-spacing-24;
  telia-heading {
    padding-bottom: $telia-spacing-12;
  }
  .second-content-row {
    display: flex;
    padding: $telia-spacing-24 0;
    &::before {
      content: attr(note-text);
      @include telia-paragraph-100;
      font-weight: $telia-typography-weight-bold;
      padding-right: $telia-spacing-4;
    }
  }
}
div.button-footer {
  display: flex;
  justify-content: flex-end;
  padding: $telia-spacing-24;
}
</style>
