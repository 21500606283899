<script setup lang="ts">
import useDetailPage from "../composables/detail-page";

import { translateMixin } from "../locale/index";

const t = translateMixin.methods.t;
const { clearSelectedSubscriptions } = useDetailPage();
</script>

<template>
  <telia-button
    t-id="clear-subscriptions-button"
    :ally-label="t('clearSelectedSubscriptionsButton')"
    variant="tertiary-purple"
    :text="t('clearSelectedSubscriptionsButton')"
    type="button"
    @click="clearSelectedSubscriptions"
  ></telia-button>
</template>
