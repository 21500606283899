<script setup lang="ts">
import { computed } from "vue";

import useDetailPage from "../composables/detail-page";

import { translateMixin } from "../locale/index";

import { ISubscription } from "../services/interfaces/IState";
import { SOURCE } from "../services/interfaces/constants";
import { useFlag } from "@unleash/proxy-client-vue";

const t = translateMixin.methods.t;
const { detailPageState, checkboxClicked, sourceIsBRM } = useDetailPage();
const isBrmEnabled = useFlag("b2b-move-brm-billing-accounts");
const tableHeaders = computed(() => {
  return [
    { title: t("mybusiness.subscription"), type: "text" },
    { title: t("mybusiness.user"), type: "text" },
    { title: t("mybusiness.subscriptionNumber"), type: "text" },
  ];
});

const rowData = computed(() => {
  return detailPageState.value.data.tableData.map(
    checkboxOptions.value.rowsEnabled ? tableDataForMobileAccount : tableDataForAccount
  );
});

const selectedSubscriptions = computed(() => {
  return detailPageState.value.data.selectedSubscriptions;
});

const subscriptionsList = computed(() => {
  return detailPageState.value.data.accountSubscriptions;
});

const tableMessage = computed(() => {
  return (
    fetchSubscriptionListError.value ||
    overTenSelectedSubscriptions.value ||
    noSubscriptionsConnected.value
  );
});

const overTenSelectedSubscriptions = computed(() => {
  return selectedSubscriptions.value.length > 10 ? t("overTenSelectedSubscriptions") : "";
});

const noSubscriptionsConnected = computed(() => {
  return subscriptionsList.value.length === 0 ? t("noSubscriptionsConnected") : "";
});

const checkboxOptions = computed(() => {
  return detailPageState.value.data.billingAccountDetails.isMobileBillingAccount || isBRM.value
    ? { headerEnabled: false, rowsEnabled: true, headerText: t("mybusiness.select") }
    : { headerEnabled: false, rowsEnabled: false };
});

const isBRM = computed(() => {
  return isBrmEnabled.value && sourceIsBRM();
});

const loadingTemplate = computed(() => {
  return detailPageState.value.layout.fetchSubscriptionList.loading;
});

const fetchSubscriptionListError = computed(() => {
  return detailPageState.value.layout.fetchSubscriptionList.error
    ? t("fetchSubscriptionList.errorMessage")
    : "";
});

const rowCheckboxClicked = (event: CustomEvent) => {
  checkboxClicked(event.detail);
};

const tableDataForMobileAccount = (subscription: ISubscription) => {
  const isChecked = detailPageState.value.data.selectedSubscriptions.includes(subscription);
  return [isChecked, subscription.offeringName, subscription.user, subscription.subscriptionId];
};

const tableDataForAccount = (subscription: ISubscription) => {
  return [subscription.offeringName, subscription.user, subscription.subscriptionId];
};
</script>

<template>
  <div class="detail-table-wrapper" t-id="detail-table-component">
    <b2x-table
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(rowData)"
      @rowCheckboxClicked="rowCheckboxClicked"
      :is-select-row-checkbox="JSON.stringify(checkboxOptions)"
      :message="tableMessage"
      :is-loading="loadingTemplate"
    ></b2x-table>
  </div>
</template>
