<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import useEditBillingAccount from "../../composables/edit-billing-account";

import { translateMixin } from "../../locale";

import ChangeAddressAccordionItem from "./change-address-accordion-item.vue";
import EditInvoiceMethodAccordionItem from "./edit-invoice-method-accordion-item.vue";

onMounted(() => {
  initialize(props.scopeId, props.organisationTscid, props.accountNumber, props.source);
});

const props = defineProps<{
  scopeId: string;
  organisationTscid: string;
  accountNumber: string;
  source: string;
}>();

const t = translateMixin.methods.t;
const {
  editBillingAccountState,
  initialize,
  closeEditBillingAccountDrawer,
  closeEditBillingAccountDrawerNotification,
} = useEditBillingAccount();

const layoutEditbillingAccount = computed(() => {
  return editBillingAccountState.value.layout;
});

const displayDrawer = computed(() => {
  return layoutEditbillingAccount.value.isDrawerOpen;
});

const notificationStatus = computed(() => {
  return layoutEditbillingAccount.value.notification.status;
});

const notificationHeader = computed(() => {
  return layoutEditbillingAccount.value.notification.header;
});

const notificationText = computed(() => {
  return layoutEditbillingAccount.value.notification.message;
});

const showNotification = computed(() => {
  return layoutEditbillingAccount.value.notification.show;
});

const closeNotification = () => {
  closeEditBillingAccountDrawerNotification();
};

const onContactSupport = () => {
  closeEditBillingAccountDrawer();

  setTimeout(() => {
    window.dispatchEvent(new CustomEvent("b2bCreateCaseOpen", { bubbles: true, composed: true }));
  }, 200);
};
</script>

<template>
  <b2x-drawer
    :heading="t('mybusiness.editBillingAccountDetails')"
    drawer-id="edit-billing-account-drawer"
    t-id="edit-billing-account-drawer"
    position="right"
    @drawerClose="closeEditBillingAccountDrawer"
    :is-open="displayDrawer"
    @vocaClose="closeNotification"
  >
    <div class="accordion__container">
      <telia-accordion>
        <edit-invoice-method-accordion-item @contact-support="onContactSupport" />
        <change-address-accordion-item />
      </telia-accordion>
    </div>
    <Transition name="notification">
      <div class="notification" v-if="showNotification" t-id="drawer-notification">
        <telia-notification
          :status="notificationStatus"
          :button-text="t('mybusiness.close')"
          :button-aria-label="t('drawer.moveSubscriptions.message.ariaLabels.button')"
          heading-tag="h2"
        >
          <span slot="heading">{{ notificationHeader }}</span>
          <telia-text-spacing slot="content">
            <telia-p>{{ notificationText }}</telia-p>
          </telia-text-spacing>
        </telia-notification>
      </div>
    </Transition>
  </b2x-drawer>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.accordion__container {
  margin-top: $telia-spacing-48;

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
}

div.notification {
  margin-top: $telia-spacing-12;
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 1.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}
</style>
