<script setup lang="ts">
import { onMounted, ref, computed, Ref } from "vue";

import useMoveSubscriptions from "../../composables/move-subscriptions";
import useDetailPage from "../../composables/detail-page";

import { translateMixin } from "../../locale";

import ExistingBillingAccountContent from "./existing-billing-account-content.vue";
import NewBillingAccountContent from "./new-billing-account-content.vue";
import { default as BrmBillingAccountContent } from "../form-fields/create-brm-billing-account.vue";
import { applyPureReactInVue } from "veaury";
import { RadioButtonGroup } from "@purpurds/purpur";
import { useFlag } from "@unleash/proxy-client-vue";

const { sourceIsBRM } = useDetailPage();
const isBrmEnabled = useFlag("b2b-move-brm-billing-accounts");
const isBRM = computed(() => {
  return isBrmEnabled.value && sourceIsBRM();
});
const PurpurRadioButtonGroup = applyPureReactInVue(RadioButtonGroup);
const t = translateMixin.methods.t;
const { moveSubscriptionsState, closeMoveSubscriptionDrawer } = useMoveSubscriptions();

const radioButtonOptions = [
  {
    id: 1,
    label: t("mybusiness.toExistingBillingAccount"),
    value: "ExistingBillingAccountOption",
    component: ExistingBillingAccountContent,
  },
  {
    id: 2,
    label: t("mybusiness.toNewBillingAccount"),
    value: "NewBillingAccountOption",
    component: isBRM.value ? BrmBillingAccountContent : NewBillingAccountContent,
  },
];
const defaultStartValue: string = radioButtonOptions[0].value;
const isDrawerOpen = ref(false);
const radioButtonOptionChosen: Ref<string> = ref(defaultStartValue);

onMounted(() => {
  setTimeout(() => {
    isDrawerOpen.value = true;
  }, 5);
});

const displayDrawer = computed(() => {
  return moveSubscriptionsState.value.layout.moveSubscriptions.isDrawerOpen && isDrawerOpen.value;
});

function handleRadioButtonValueChange(value: string): void {
  radioButtonOptions.forEach((option) => {
    if (value === option.value) {
      radioButtonOptionChosen.value = option.value;
      return;
    }
  });
}
</script>

<template>
  <div>
    <b2x-drawer
      :heading="t('mybusiness.moveSubscriptions')"
      drawer-id="move-subscription-drawer"
      t-id="move-subscription-drawer"
      position="right"
      @drawerClose="closeMoveSubscriptionDrawer"
      :is-open="displayDrawer"
    >
      <div t-id="purpur-radio-button-group">
        <PurpurRadioButtonGroup
          className="move-subscription-drawer__radio-button-group"
          data-testid="purpur-radio-button-group"
          required="true"
          :label="t('drawer.moveSubscriptions.label')"
          :items="radioButtonOptions"
          :value="radioButtonOptionChosen"
          :onValueChange="handleRadioButtonValueChange"
        ></PurpurRadioButtonGroup>

        <template v-for="option in radioButtonOptions" :id="option.id">
          <component
            v-if="option.value === radioButtonOptionChosen"
            v-bind:is="option.component"
          ></component>
        </template>
      </div>
    </b2x-drawer>
  </div>
</template>

<style lang="scss">
@import "@purpurds/purpur/styles";

.move-subscription-drawer {
  &__radio-button-group {
    margin-bottom: var(--purpur-spacing-400);
  }
}
</style>
