<script setup lang="ts">
import { ref, computed } from "vue";

import useDetailPage from "../../composables/detail-page";
import useEditBillingAccount from "../../composables/edit-billing-account";

import ContactPersonField from "../form-fields/contact-person-field.vue";

import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
const inputEmail = ref("");
const inputRepeatEmail = ref("");
const { detailPageState } = useDetailPage();
const { editBillingAccountState, updateDeliveryMethodEmailAddress } = useEditBillingAccount();

const currentEmail = computed(() => {
  return detailPageState.value.data.billingAccountDetails.invoiceDeliveryEmail;
});

const inputEmailMatching = computed(() => {
  return inputRepeatEmail.value === inputEmail.value;
});

const submittingForm = computed(() => {
  return editBillingAccountState.value.layout.updateDeliveryMethod.loading;
});

const errorMessage = computed(() => {
  return !inputEmailMatching.value ? t("drawer.shared.form.emailPatternErrorMessage") : "";
});

const submit = ({ target }) => {
  const emailAddress = target.email.value;
  const phoneNumber = target.phoneNumber.value;
  updateDeliveryMethodEmailAddress(emailAddress, phoneNumber, true);
};

const handleInputEmail = ({ target }) => {
  inputEmail.value = target.value;
};

const handleInputRepeatEmail = ({ target }) => {
  inputRepeatEmail.value = target.value;
};
</script>

<template>
  <telia-tab-content :name="t('mybusiness.createNewRecipient')" t-id="edit-invoice-email-tab2">
    <telia-form name="createNewRecipientForm" @submit.prevent="submit">
      <fieldset class="telia-fieldset">
        <legend v-if="currentEmail">
          <telia-p variant="paragraph-100"
            ><strong>{{ t("mybusiness.legend.newRecipient") }}</strong>
            <br />
            {{ currentEmail }}
          </telia-p>
        </legend>
        <telia-heading t-id="email-header-input-fields" variant="title-100" tag="h2">{{
          t("drawer.editDeliveryMethodAccount.email.emailHeader")
        }}</telia-heading>
        <telia-text-input
          t-id="email-input-field"
          :label="t('drawer.editDeliveryMethodAccount.email.form.labels.emailInput')"
          type="email"
          name="email"
          :additional="t('drawer.shared.form.maxLength', { 0: '50' })"
          :maxlength="50"
          @input="handleInputEmail"
          required="true"
          :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
          :email-error-message="t('drawer.shared.form.invalidEmailErrorMessage')"
        ></telia-text-input>
        <telia-text-input
          t-id="email-repeat-input-field"
          :label="t('drawer.editDeliveryMethodAccount.email.form.labels.repeatEmailInput')"
          type="email"
          name="repeatEmail"
          :additional="t('drawer.shared.form.maxLength', { 0: '50' })"
          :maxlength="50"
          @input="handleInputRepeatEmail"
          required="true"
          :error-message="errorMessage"
          :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
          :email-error-message="t('drawer.shared.form.invalidEmailErrorMessage')"
        ></telia-text-input>
        <contact-person-field />
        <div t-id="edit-invoice-item-tab-2-information-notification" class="notification">
          <telia-notification status="information" heading-tag="h2">
            <span slot="heading">{{
              t("drawer.editDeliveryMethodAccount.email.notification.heading")
            }}</span>
            <telia-text-spacing slot="content">
              <telia-p>{{
                t("drawer.editDeliveryMethodAccount.email.notification.description")
              }}</telia-p>
            </telia-text-spacing>
          </telia-notification>
        </div>
        <telia-text-spacing>
          <telia-p t-id="checkbox-header" variant="paragraph-100">{{
            t("drawer.editDeliveryMethodAccount.email.checkboxHeader")
          }}</telia-p>
        </telia-text-spacing>
        <telia-checkbox
          required="true"
          :required-error-message="t('drawer.shared.form.requiredErrorMessage')"
        >
          {{ t("drawer.editDeliveryMethodAccount.email.form.labels.checkbox") }}
        </telia-checkbox>
        <telia-button
          t-id="submit-button"
          :text="t('mybusiness.save_delivery_method')"
          variant="primary"
          :disabled="submittingForm"
          type="submit"
        ></telia-button>
      </fieldset>
    </telia-form>
  </telia-tab-content>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

div.form__submit--wrapper {
  display: flex;
  align-items: center;
  margin-top: $telia-spacing-48;

  b2x-spinner {
    margin-left: $telia-spacing-12;
  }
}

div.notification {
  margin-top: $telia-spacing-32;
  margin-bottom: $telia-spacing-24;
}

fieldset.telia-fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
</style>
