import { ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";

import useInfoModal from "./info-modal";
import useDetailPage from "./detail-page";

import { ISubscription } from "../services/interfaces/IState";
import { createStatemoveSubscription } from "../services/consts/create_state";
import {
  IMoveSubscriptionState,
  IBillingAccount,
} from "../services/interfaces/IMoveSubscriptionState";
import { SOURCE } from "../services/interfaces/constants";
import {
  createNewCorpBillingAccount,
  fetchBillingAccounts,
  fetchBrmBillingAccounts,
  moveSubscriptionsToExistingMobillAccount,
  moveSubscriptionsToExistingBrmAccount,
  moveSubscriptionsToNewBrmAccount,
} from "../services/corpCustomerBillingAccounts";

import {
  trackChangeAccountToExisting,
  trackChangeAccountToExistingComplete,
  trackChangeAccountToNew,
  trackChangeAccountToNewComplete,
  trackChangedGroup,
  trackOpenDrawer,
  trackChangedGroupErrorToNew,
  trackChangedGroupErrorToExisting,
} from "../utils/trackGA4";
import type {
  AddressRequestDTO,
  MoveSubscriptionDTO,
  MoveSubscriptionsBRMResponseDTO,
} from "@telia/b2b-rest-client/dist/corp-customer-billing-accounts";

const { updateInfoModalContentPartiallySuccessfulMove, updateInfoModalContentSuccessfulMove } =
  useInfoModal();
const { tryToFetchSubscriptionsList } = useDetailPage();
const moveSubscriptionsState = ref<IMoveSubscriptionState>(createStatemoveSubscription());
const analytics = ref({
  action,
  category,
  label,
});

const errorMessages = {
  partiallyFailToNew: "Partially failed to move subscriptions to a new account",
  partiallyFailToExisting: "Partially failed to move subscriptions to a existing account",
  failToNew: "Failed to move subscriptions to a new account",
  failToExisting: "Failed to move subscriptions to a existing account",
};

const useMoveSubscriptions = () => {
  const initialize = (scopeId: string, tscid: string, accountNumber: string, source: string) => {
    moveSubscriptionsState.value.data.scopeId = scopeId;
    moveSubscriptionsState.value.data.tscid = tscid;
    moveSubscriptionsState.value.data.accountNumber = accountNumber;
    moveSubscriptionsState.value.data.source = source;
    moveSubscriptionsState.value.data.billingAccounts = [];
  };

  const openMoveSubscriptionDrawer = (selectedSubscriptions: ISubscription[]): void => {
    if (moveSubscriptionsState.value.data.billingAccounts.length === 0) {
      _tryToFetchAccounts();
    }

    moveSubscriptionsState.value.data.selectedSubscriptions = selectedSubscriptions;
    moveSubscriptionsState.value.layout.moveSubscriptions.isDrawerOpen = true;
    _trackEvent(analytics.value.label.OPEN_MOVE_SUBSCRIPTION_DRAWER, analytics.value.action.OPEN);
    trackOpenDrawer();
  };

  const closeMoveSubscriptionDrawer = (): void => {
    moveSubscriptionsState.value.layout.moveSubscriptions.isDrawerOpen = false;
    _trackEvent(analytics.value.label.CLOSE_DRAWER_GA_LABEL, analytics.value.action.CLICK);
  };

  const getSuggestionsForBillingAccounts = (
    inputValue: string,
    gaEventTracked: boolean
  ): string[] => {
    if (!gaEventTracked) {
      _trackEvent(analytics.value.label.FAKTURAKONTO_SEARCH, analytics.value.action.INITIATED);
    }

    return moveSubscriptionsState.value.data.billingAccounts
      .filter((account: IBillingAccount) => _displayInSuggestions(account, inputValue))
      .map((account: IBillingAccount) => account.displayName);
  };

  const findSuggestion = (displayName: string): IBillingAccount | undefined => {
    _trackEvent(analytics.value.label.FAKTURAKONTO_SEARCH, analytics.value.action.SELECTED);
    _trackEvent(analytics.value.label.FAKTURAKONTO_SEARCH, analytics.value.action.COMPLETED);
    return moveSubscriptionsState.value.data.billingAccounts.find(
      (account: IBillingAccount) => account.displayName === displayName
    );
  };

  const moveSubscription = async (accountId: string): Promise<boolean> => {
    return await _tryToMoveSubscriptions(accountId);
  };

  const moveToNewMobillAccount = async (
    currentBillingAccountNumber: string,
    subscriptions: string[],
    accountReference: string,
    city: string,
    zip: string,
    street: string,
    name: string
  ): Promise<void> => {
    const submitFunction = async (): Promise<MoveSubscriptionDTO> => {
      const moveToNewBillingAccountRequest = {
        currentBillingAccountNumber,
        subscriptions,
        reference: accountReference,
        address: street,
        zip,
        city,
        name,
      };
      return await createNewCorpBillingAccount(
        moveSubscriptionsState.value.data.scopeId,
        moveSubscriptionsState.value.data.tscid,
        moveToNewBillingAccountRequest
      );
    };

    const onSuccess = (response: MoveSubscriptionDTO): void => {
      if (!response.failedSubscriptions) {
        updateInfoModalContentSuccessfulMove(
          `${moveSubscriptionsState.value.data.selectedSubscriptions.length}`,
          response.accountNumber!
        );
      } else {
        updateInfoModalContentPartiallySuccessfulMove(
          response.accountNumber!,
          response.failedSubscriptions,
          `${
            moveSubscriptionsState.value.data.selectedSubscriptions.length -
            response.failedSubscriptions.length
          }`
        );
        trackChangedGroupErrorToNew(errorMessages.partiallyFailToNew);
        _logError("Partially failed to move subscriptions to a new account");
      }
    };
    return await _tryToMoveSubscriptionsToNewBillingAccount(submitFunction, onSuccess);
  };

  const moveToNewBrmAccount = async (
    scopeId: string,
    tscid: string,
    subscriptions: string[],
    reference: string,
    address: AddressRequestDTO,
    deliveryMethod: "EMAIL" | "LETTER",
    currentBillingAccountNumber?: string,
    emailAddress?: string,
    billingCycle?: string
  ): Promise<void> => {
    const submitFunction = async (): Promise<MoveSubscriptionsBRMResponseDTO> => {
      return await moveSubscriptionsToNewBrmAccount(scopeId, tscid, {
        currentBillingAccountNumber,
        subscriptions,
        reference,
        address,
        deliveryMethod,
        emailAddress,
        billingCycle,
      });
    };

    const onSuccess = (response: MoveSubscriptionsBRMResponseDTO): void => {
      trackChangeAccountToNewComplete();
      updateInfoModalContentSuccessfulMove(
        `${moveSubscriptionsState.value.data.selectedSubscriptions.length}`,
        response.targetAccountId
      );
    };

    return await _tryToMoveSubscriptionsToNewBillingAccount(submitFunction, onSuccess);
  };

  const closeMoveSubscriptionsNotification = (type: "existingAccount" | "newAccount"): void => {
    moveSubscriptionsState.value.layout.moveSubscriptions[type].error = false;
  };

  return {
    moveSubscriptionsState,
    initialize,
    openMoveSubscriptionDrawer,
    closeMoveSubscriptionDrawer,
    getSuggestionsForBillingAccounts,
    findSuggestion,
    moveSubscription,
    moveToNewMobillAccount,
    moveToNewBrmAccount,
    closeMoveSubscriptionsNotification,
  };
};

export default useMoveSubscriptions;

const _tryToMoveSubscriptions = async (targetAccountNumber: string): Promise<boolean> => {
  try {
    moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.loading = true;
    trackChangeAccountToExisting();

    moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.error = false;
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE, analytics.value.action.INITIATED);

    trackChangedGroup();

    const subscriptionsToMove = {
      subscriptions: moveSubscriptionsState.value.data.selectedSubscriptions.map(
        (sub) => sub.subscriptionId
      ),
    };

    if (moveSubscriptionsState.value.data.source.toLowerCase() == SOURCE.BRM) {
      const brmRequestBody = {
        subscriptions: subscriptionsToMove.subscriptions,
        sourceAccountId: moveSubscriptionsState.value.data.accountNumber,
        targetAccountId: targetAccountNumber,
      };

      await moveSubscriptionsToExistingBrmAccount(
        moveSubscriptionsState.value.data.scopeId,
        moveSubscriptionsState.value.data.tscid,
        brmRequestBody
      );
      updateInfoModalContentSuccessfulMove(
        `${moveSubscriptionsState.value.data.selectedSubscriptions.length}`,
        targetAccountNumber
      );
    } else {
      const response: MoveSubscriptionDTO = await moveSubscriptionsToExistingMobillAccount(
        moveSubscriptionsState.value.data.scopeId,
        moveSubscriptionsState.value.data.tscid,
        targetAccountNumber,
        subscriptionsToMove
      );
      if (!response.failedSubscriptions) {
        updateInfoModalContentSuccessfulMove(
          `${moveSubscriptionsState.value.data.selectedSubscriptions.length}`,
          targetAccountNumber
        );
      } else {
        updateInfoModalContentPartiallySuccessfulMove(
          targetAccountNumber,
          response.failedSubscriptions,
          `${
            moveSubscriptionsState.value.data.selectedSubscriptions.length -
            response.failedSubscriptions.length
          }`
        );
        trackChangedGroupErrorToExisting(errorMessages.partiallyFailToExisting);
        _logError("Partially failed to move subscriptions to existing account");
      }
    }
    trackChangeAccountToExistingComplete();
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE, analytics.value.action.COMPLETED);
    tryToFetchSubscriptionsList();
    return true;
  } catch (error) {
    moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.error = true;
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE, analytics.value.action.ERROR);
    trackChangedGroupErrorToExisting(errorMessages.failToExisting);
    _logError("Failed to move subscriptions to existing account");
    return false;
  } finally {
    moveSubscriptionsState.value.layout.moveSubscriptions.existingAccount.loading = false;
  }
};

const _tryToMoveSubscriptionsToNewBillingAccount = async <
  T extends MoveSubscriptionDTO | MoveSubscriptionsBRMResponseDTO,
>(
  submitFunction: () => Promise<T>,
  onSuccessFunction: (response: T) => void
): Promise<void> => {
  try {
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE_NEW, analytics.value.action.INITIATED);
    moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.loading = true;
    trackChangeAccountToNew();
    moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.error = false;

    const result = await submitFunction();
    onSuccessFunction(result);

    moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.loading = false;
    trackChangeAccountToNewComplete();
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE_NEW, analytics.value.action.COMPLETED);
    tryToFetchSubscriptionsList();
  } catch (error) {
    moveSubscriptionsState.value.layout.moveSubscriptions.newAccount.error = true;
    _trackEvent(analytics.value.label.FAKTURAKONTO_MOVE_NEW, analytics.value.action.ERROR);
    trackChangedGroupErrorToNew(errorMessages.failToNew);
    _logError("Failed to move subscriptions to a new account");
  }
};

const _displayInSuggestions = (account: IBillingAccount, inputValue: string): boolean => {
  return (
    account.displayName.toLowerCase().includes(inputValue.toLowerCase()) &&
    account.accountNumber !== moveSubscriptionsState.value.data.accountNumber
  );
};

const _tryToFetchAccounts = async (): Promise<void> => {
  try {
    moveSubscriptionsState.value.layout.fetchBillingAccounts.loading = true;
    if (moveSubscriptionsState.value.data.source == SOURCE.BRM) {
      moveSubscriptionsState.value.data.billingAccounts = await fetchBrmBillingAccounts(
        moveSubscriptionsState.value.data.scopeId,
        moveSubscriptionsState.value.data.tscid
      );
    } else {
      moveSubscriptionsState.value.data.billingAccounts = await fetchBillingAccounts(
        moveSubscriptionsState.value.data.scopeId,
        moveSubscriptionsState.value.data.tscid,
        moveSubscriptionsState.value.data.accountNumber
      );
    }

    _trackEvent(
      analytics.value.label.OPEN_MOVE_SUBSCRIPTION_DRAWER,
      analytics.value.action.COMPLETED
    );
  } catch (error) {
    _trackEvent(analytics.value.label.OPEN_MOVE_SUBSCRIPTION_DRAWER, analytics.value.action.ERROR);
    _logError("Failed to fetch mobile billing accounts");
  } finally {
    moveSubscriptionsState.value.layout.fetchBillingAccounts.loading = false;
  }
};

const _trackEvent = (label: string, action: string): void => {
  const category = analytics.value.category.INVOICE_ACCOUNT_DETAILS;
  trackEvent(category, action, label);
};

const _logError = (message: string): void => {
  logError("b2b-billing-account-detail-page", message);
};
