<script setup lang="ts">
import { ref, computed } from "vue";

import useDetailPage from "../composables/detail-page";

import { translateMixin } from "../locale/index";

const t = translateMixin.methods.t;
const inputValue = ref("");
const { filterTableData } = useDetailPage();
const timeout = ref<ReturnType<typeof setTimeout> | undefined>();

const showSearchIcon = computed(() => {
  return inputValue.value === "";
});

const handleInputChange = ({ target }) => {
  inputValue.value = target.value;

  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    filterTableData(inputValue.value);
  }, 500);
};
</script>

<template>
  <div class="detail-search-wrapper" t-id="subscription-filter-component">
    <telia-fieldset :legend="t('mybusiness.incomingSubscriptions')">
      <telia-text-input @input="handleInputChange" :label="t('mybusiness.searchSubscription')">
        <div class="search-icon-wrapper">
          <telia-icon
            name="search"
            t-id="filter-subscriptions-search-icon"
            v-show="showSearchIcon"
          ></telia-icon>
        </div>
      </telia-text-input>
    </telia-fieldset>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";

.detail-search-wrapper {
  min-height: 5em;
}

telia-text-input {
  position: relative;
}

.search-icon-wrapper {
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  pointer-events: none;
  color: $telia-gray-500;
}
</style>
