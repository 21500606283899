import { ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { corpCustomerBillingAccounts } from "@telia/b2b-rest-client";
import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";

import useInfoModal from "./info-modal";
import { translateMixin } from "../locale";

import { createEditBillingAccountState } from "../services/consts/create_state";
import {
  updateBillingAddress,
  updateDeliveryMethod,
  fetchAvailableEmails,
} from "../services/corpCustomerBillingAccounts";
import {
  trackOpenDrawer,
  trackToUpdateBillingAddress,
  trackUpdateDeliveryMethodEdiAddress,
  trackUpdateDeliveryMethodEmailAddress,
  trackUpdateDeliveryMethodPostal,
} from "../utils/trackGA4";

const translate = translateMixin.methods.t;
const editBillingAccountState = ref(createEditBillingAccountState());
const analytics = ref({
  action,
  category,
  label,
});
const { updateEditBillingAccountInfoModal } = useInfoModal();

const useEditBillingAccount = () => {
  const initialize = (
    scopeId: string,
    tscid: string,
    accountNumber: string,
    source: string
  ): void => {
    editBillingAccountState.value.data.scopeId = scopeId;
    editBillingAccountState.value.data.tscid = tscid;
    editBillingAccountState.value.data.accountNumber = accountNumber;
    editBillingAccountState.value.data.source = source;
  };

  const openEditBillingAccountDrawer = (): void => {
    editBillingAccountState.value.layout.isDrawerOpen = true;
    _trackEvent(analytics.value.label.OPEN_EDIT_ACCOUNT_DRAWER, analytics.value.action.OPEN);
    trackOpenDrawer();

    if (editBillingAccountState.value.data.availableEmails.length < 1) {
      _tryToFetchAllAvailbleEmails();
    }
  };

  const closeEditBillingAccountDrawer = (): void => {
    editBillingAccountState.value.layout.isDrawerOpen = false;
  };

  const updateDeliveryMethodEdiAddress = (reference: string): void => {
    _tryToUpdateDeliveryMethodEdiAddress(reference);
  };

  const updateDeliveryMethodEmailAddress = (
    emailAddress: string,
    phoneNumber: string,
    newEmailForm: boolean
  ): void => {
    _tryToUpdateDeliveryMethodEmailAddress(emailAddress, phoneNumber, newEmailForm);
  };

  const updateDeliveryMethodPostal = (
    updateDeliveryMethodRequest: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO
  ): void => {
    const deliveryMethod = "postal";
    editBillingAccountState.value.data.updatedData.deliveryMethod = deliveryMethod;
    updateDeliveryMethodRequest.deliveryMethod = deliveryMethod;
    _tryToUpdateDeliveryMethodPostal(updateDeliveryMethodRequest);
  };

  const closeEditBillingAccountDrawerNotification = (): void => {
    _resetEditBillingAccountDrawerNotification();
  };

  const updateBillingAddress = (
    updateBillingAddressRequest: corpCustomerBillingAccounts.UpdateBillingAddressRequestDTO
  ): void => {
    _tryToUpdateBillingAddress(updateBillingAddressRequest);
  };

  return {
    editBillingAccountState,
    initialize,
    openEditBillingAccountDrawer,
    closeEditBillingAccountDrawer,
    updateDeliveryMethodEdiAddress,
    updateDeliveryMethodEmailAddress,
    updateDeliveryMethodPostal,
    closeEditBillingAccountDrawerNotification,
    updateBillingAddress,
  };
};

export default useEditBillingAccount;

const _tryToUpdateDeliveryMethodEmailAddress = async (
  emailAddress: string,
  phoneNumber: string,
  newEmailForm: boolean
): Promise<void> => {
  try {
    editBillingAccountState.value.layout.updateDeliveryMethod.loading = true;
    _trackEvent(
      analytics.value.label.CHANGE_DELIVERY_METHOD_EMAIL,
      analytics.value.action.INITIATED
    );
    trackUpdateDeliveryMethodEmailAddress();

    const deliveryMethod = "email";
    editBillingAccountState.value.data.updatedData.deliveryMethod = deliveryMethod;
    const updateDeliveryMethodRequestData: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO =
      {
        emailAddress,
        phoneNumber,
        deliveryMethod,
      };

    await _updateDeliveryMethod(updateDeliveryMethodRequestData);
    editBillingAccountState.value.layout.isDrawerOpen = false;
    updateEditBillingAccountInfoModal();
    _trackEvent(
      analytics.value.label.CHANGE_DELIVERY_METHOD_EMAIL,
      analytics.value.action.COMPLETED
    );
  } catch (error) {
    _updateEditBillingAccountNotificationContentErrorMoving();
    _trackEvent(analytics.value.label.CHANGE_DELIVERY_METHOD_EMAIL, analytics.value.action.ERROR);
    const errorString = newEmailForm ? "new email form" : "existing email";
    _logError(`Failed to update delivery method to EMAIL - ${errorString}`);
  } finally {
    editBillingAccountState.value.layout.updateDeliveryMethod.loading = false;
  }
};

const _tryToUpdateBillingAddress = async (
  updateBillingAddressRequest: corpCustomerBillingAccounts.UpdateBillingAddressRequestDTO
): Promise<void> => {
  try {
    _trackEvent(analytics.value.label.UPDATE_ADDRESS, analytics.value.action.INITIATED);
    trackToUpdateBillingAddress();

    editBillingAccountState.value.layout.updateBillingAddress.loading = true;
    _resetEditBillingAccountDrawerNotification();

    await updateBillingAddress(
      editBillingAccountState.value.data.scopeId,
      editBillingAccountState.value.data.tscid,
      editBillingAccountState.value.data.accountNumber,
      editBillingAccountState.value.data.source,
      updateBillingAddressRequest
    );
    updateEditBillingAccountInfoModal();
    _trackEvent(analytics.value.label.UPDATE_ADDRESS, analytics.value.action.COMPLETED);
  } catch (error) {
    _updateEditBillingAccountNotificationContentErrorMoving();
    _trackEvent(analytics.value.label.UPDATE_ADDRESS, analytics.value.action.ERROR);
    _logError("Failed to submit update billing address form");
  } finally {
    editBillingAccountState.value.layout.updateBillingAddress.loading = false;
  }
};

const _tryToUpdateDeliveryMethodPostal = async (
  updateDeliveryMethodRequest: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO
): Promise<void> => {
  try {
    _trackEvent(
      analytics.value.label.UPDATE_DELIVERY_METHOD_POSTAL,
      analytics.value.action.INITIATED
    );
    trackUpdateDeliveryMethodPostal();

    editBillingAccountState.value.layout.updateDeliveryMethod.loading = true;
    _resetEditBillingAccountDrawerNotification();

    await _updateDeliveryMethod(updateDeliveryMethodRequest);
    _trackEvent(
      analytics.value.label.UPDATE_DELIVERY_METHOD_POSTAL,
      analytics.value.action.COMPLETED
    );
    updateEditBillingAccountInfoModal();
  } catch (error) {
    _updateEditBillingAccountNotificationContentErrorMoving();
    _trackEvent(analytics.value.label.UPDATE_DELIVERY_METHOD_POSTAL, analytics.value.action.ERROR);
    _logError("Failed to update delivery method to POSTAL");
  } finally {
    editBillingAccountState.value.layout.updateDeliveryMethod.loading = false;
  }
};

const _updateDeliveryMethod = async (
  updateDeliveryMethodRequest: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO
): Promise<void> => {
  await updateDeliveryMethod(
    editBillingAccountState.value.data.scopeId,
    editBillingAccountState.value.data.tscid,
    editBillingAccountState.value.data.accountNumber,
    editBillingAccountState.value.data.source,
    updateDeliveryMethodRequest
  );
};

const _tryToFetchAllAvailbleEmails = async (): Promise<void> => {
  try {
    editBillingAccountState.value.data.availableEmails = await fetchAvailableEmails(
      editBillingAccountState.value.data.scopeId,
      editBillingAccountState.value.data.tscid
    );
  } catch (error) {
    _logError("Failed to fetch all available emails");
  }
};

const _tryToUpdateDeliveryMethodEdiAddress = async (reference: string): Promise<void> => {
  try {
    editBillingAccountState.value.layout.updateDeliveryMethod.loading = true;
    _trackEvent(analytics.value.label.UPDATE_DELIVERY_METHOD_EDI, analytics.value.action.INITIATED);
    trackUpdateDeliveryMethodEdiAddress();

    const deliveryMethod = "edi";
    editBillingAccountState.value.data.updatedData.deliveryMethod = deliveryMethod;
    const updateDeliveryMethodRequestData: corpCustomerBillingAccounts.UpdateDeliveryMethodRequestDTO =
      {
        deliveryMethod,
        reference,
      };

    await _updateDeliveryMethod(updateDeliveryMethodRequestData);

    updateEditBillingAccountInfoModal();
    _trackEvent(analytics.value.label.UPDATE_DELIVERY_METHOD_EDI, analytics.value.action.COMPLETED);
  } catch (error) {
    _updateEditBillingAccountNotificationContentErrorMoving();
    _trackEvent(analytics.value.label.UPDATE_DELIVERY_METHOD_EDI, analytics.value.action.ERROR);
    _logError("Failed to update delivery method to EDI");
  } finally {
    editBillingAccountState.value.layout.updateDeliveryMethod.loading = false;
  }
};

const _updateEditBillingAccountNotificationContentErrorMoving = (): void => {
  _updateEditBillingAccountNotificationContent(
    "error",
    translate("drawer.editBillingAccount.message.errorMoving.content"),
    translate("drawer.editBillingAccount.message.errorMoving.header")
  );
  editBillingAccountState.value.layout.notification.show = true;
};

const _resetEditBillingAccountDrawerNotification = (): void => {
  _updateEditBillingAccountNotificationContent("", "", "");
  editBillingAccountState.value.layout.notification.show = false;
};

const _updateEditBillingAccountNotificationContent = (
  status: string,
  message: string,
  header = ""
): void => {
  editBillingAccountState.value.layout.notification.status = status;
  editBillingAccountState.value.layout.notification.message = message;
  editBillingAccountState.value.layout.notification.header = header;
};

const _trackEvent = (label: string, action: string): void => {
  const category = analytics.value.category.INVOICE_ACCOUNT_DETAILS;
  trackEvent(category, action, label);
};

const _logError = (message: string): void => {
  logError("b2b-billing-account-detail-page", message);
};
